import {
    // FETCH_ANNOUNCEMENT_START,
    // FETCH_ANNOUNCEMENT_SUCCESS,
    // FETCH_ANNOUNCEMENT_FAILURE,
    SET_TOTAL_ANNOUNCEMENTS,
    SET_SHOW_ALL_CHECKED_TRUE,
    SET_SHOW_ALL_CHECKED_FALSE,
    SET_MARKED_AS_READ_ID,
    UNSET_MARKED_AS_READ_ID,
  } from '../actionTypes';
  
//   export const fetchAnnouncementStart = () => ({
//     type: FETCH_ANNOUNCEMENT_START,
//   });
  
//   export const fetchAnnouncementSuccess = (announcements) => ({
//     type: FETCH_ANNOUNCEMENT_SUCCESS,
//     payload: announcements,
//   });
  
//   export const fetchAnnouncementFailure = (error) => ({
//     type: FETCH_ANNOUNCEMENT_FAILURE,
//     payload: { message: error },
//   });
  
  export const setTotalAnnouncements = (total) => ({
    type: SET_TOTAL_ANNOUNCEMENTS,
    payload: total,
  });
  
  export const setShowAllCheckedTrue = () => ({
    type: SET_SHOW_ALL_CHECKED_TRUE,
  });
  
  export const setShowAllCheckedFalse = () => ({
    type: SET_SHOW_ALL_CHECKED_FALSE,
  });
  
  export const setMarkedAsReadId = (id) => ({
    type: SET_MARKED_AS_READ_ID,
    payload: id,
  });
  
  export const unsetMarkedAsReadId = () => ({
    type: UNSET_MARKED_AS_READ_ID,
  });