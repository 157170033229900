import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import styled from "@mui/system/styled";
import Stack from "@mui/material/Stack";
import { DatePicker } from "rsuite";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { setFeedback } from "../../store/redux/actions/feedback.action";
import { useDispatch, useSelector } from "react-redux";
import { fetchToDosByProjectId } from "../../apis/todos";
import { fetchProjectByEmployeeId } from "../../apis/employee";
import dayjs from "dayjs";
import { FiPlus } from "react-icons/fi";
import { convertDateIntoYYYYMMDD, getSelectStyles } from "../../utils/helper";
import TimeLineChart from "../../components/common/TimeLineChart";
// import { getTimeLineChartData } from "../../apis/timeSheet";
import {
  Checkbox,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { maxHeight } from "@mui/system";
import zIndex from "@mui/material/styles/zIndex";
// import {AddNewToDo} from "../TimesheetViewEdit/AddNewTodo"
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
}));

function isToTimeValid(fromTime, toTime) {
  const [fromHours, fromMinutes] = fromTime.split(":").map(Number);
  const [toHours, toMinutes] = toTime.split(":").map(Number);

  const fromDate = new Date(0, 0, 0, fromHours, fromMinutes);
  const toDate = new Date(0, 0, 0, toHours, toMinutes);

  return toDate > fromDate;
}

const checkCurrentDateTimeValidation = (dateValue, fromTime, toTime) => {
  if (
    convertDateIntoYYYYMMDD(dateValue) === convertDateIntoYYYYMMDD(new Date())
  ) {
    const currentHours = new Date().getHours();
    const currentMinutes = new Date().getMinutes();
    const [fromHour, fromMinute] = fromTime.split(":");
    const [toHour, toMinute] = toTime.split(":");

    if (
      parseInt(fromHour) > currentHours ||
      (parseInt(fromHour) >= currentHours &&
        parseInt(fromMinute) >= currentMinutes)
    ) {
      return false;
    } else if (
      parseInt(toHour) > currentHours ||
      (parseInt(toHour) >= currentHours && parseInt(toMinute) >= currentMinutes)
    ) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

const EditAllocatedTime = ({
  addTimeModalOpen,
  addModalClose,
  addTimeSheetHandler,
  assignee,
  employees,
  fetchReportData,
  setEmployees,
  setAllotedData,
  allocatedDataRef,
}) => {
  //   const employees = ['Employee 1', 'Employee 2', 'Employee 3','Employee 1', 'Employee 2', 'Employee 3','Employee 1', 'Employee 2', 'Employee 3','Employee 1', 'Employee 2', 'Employee 3','Employee 1', 'Employee 2', 'Employee 3'];
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.data);

  const [totalDuration, setTotalDuration] = useState("00:00");
  const [isDisableToTime, setIsDisableToTime] = useState(true);
  const [timeLineChartData, setTimeLineChartData] = useState([]);
  const [isAbleToAddEntry, setIsAbleToAddEntry] = useState(true);

  const [todo, setTodo] = useState([]);
  const [projects, setProjects] = useState([]);
  const [formData, setFormData] = useState({
    assignee: {
      value: userData.id,
      label: `${userData.first_name} ${userData.last_name}`,
    },
    project: null,
    todo: null,
    memo: "",
    // date: dayjs(),
    date: new Date(),
    from: "",
    to: "",
    reason: null,
    other: "",
    billable: "",
  });
  // console.log(employees,"employees data")
  // Options for hours (1 to 8)
  const hourOptions = Array.from({ length: 9 }, (v, i) => ({
    value: String(i).padStart(2, "0"),
    label: `${i} hr`,
  }));

  // Options for minutes (0 to 60 in steps of 5)
  const minuteOptions = Array.from({ length: 13 }, (v, i) => ({
    value: String(i * 5).padStart(2, "0"),
    label: `${i * 5} min`,
  }));

  const customSelectStyles = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 1301, // Ensure this is higher than the footer
    }),
    control: (base) => ({
      ...base,
      minHeight: "30px",
      height: "30px",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: "4px",
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: "4px",
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0px 6px",
    }),
    input: (base) => ({
      ...base,
      margin: "0px",
    }),
    menuList: (provided) => ({
      ...provided,
      zIndex: 2000,
      maxHeight: "150px", // Restrict max height of dropdown if needed
      overflowY: "auto", // Enable scrolling when options exceed max height
    }),
    // menu: (provided) => ({
    //   ...provided,
    //   zIndex: 1500, // Ensure this value is higher than the footer's z-index
    // }),
    // container: (provided) => ({
    //   ...provided,
    //   position: "relative",
    //   zIndex: 1000, // Adjust as necessary for container, if overlapping persists
    // }),
  };
  const [disableAddTodo, setDisableAddTodo] = useState(true);
  // add Todos account modal
  const [addToDosModalOpen, addSetOpen] = React.useState(false);
  const addTodosModalOpen = () => addSetOpen(true);
  const addTodosModalClose = () => addSetOpen(false);

  const [error, setError] = useState({
    assignee: "",
    project: "",
    todo: "",
    memo: "",
    date: "",
    from: "",
    to: "",
    reason: "",
    other: "",
  });

  const assigneeOptions = useMemo(() => {
    return assignee?.length
      ? assignee?.map((row) => {
          return { value: row.id, label: `${row.first_name} ${row.last_name}` };
        })
      : [];
  }, [assignee]);

  const projectOptions = useMemo(() => {
    return projects.length
      ? projects.map((row) => {
          return { value: row.id, label: row.title, billable: row.billable };
        })
      : [];
  }, [projects]);

  let todoOptions = useMemo(() => {
    return todo.length
      ? todo.map((row) => {
          return { value: row.id, label: row.description };
        })
      : [];
  }, [todo]);

  const fetchProjectHandler = useCallback(async () => {
    try {
      if (formData.assignee !== null) {
        const params = { status: "active" };
        const response = await fetchProjectByEmployeeId(
          params,
          formData.assignee.value
        );
        if (response.data.status === 200) {
          setProjects(response.data.data.projects);
        } else if (response.data.status === 404) {
          setProjects([]);
        } else {
          const errPayload = {
            code: response.data.status,
            status: "fail",
            message: response.data.message || "Something went wrong.",
          };
          dispatch(setFeedback(errPayload));
        }
      }
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
  }, [dispatch, formData.assignee]);

  const fetchTodoHandler = useCallback(
    async (newAddedTodo = null) => {
      try {
        setFormData((prev) => ({ ...prev, todo: null }));
        if (formData.assignee && formData.project) setDisableAddTodo(false);
        if (formData.project !== null && formData.assignee !== null) {
          const params = {
            project: formData.project.value,
            assignee: formData.assignee.value,
            listing: true,
            status: "pending",
          };
          const response = await fetchToDosByProjectId(params);

          if (response.data.status === 200) {
            setTodo(response.data.data.todos);
            if (newAddedTodo) {
              setFormData((prev) => ({ ...prev, todo: newAddedTodo }));
            }
          } else if (response.data.status === 404) {
            setTodo([]);
          } else {
            const errPayload = {
              code: response.data.status,
              status: "fail",
              message: response.data.message || "Something went wrong.",
            };
            dispatch(setFeedback(errPayload));
          }
        } else setTodo([]);
      } catch (error) {
        const errPayload = {
          code: error.status,
          status: "fail",
          message: error.message || "Something went wrong.",
        };
        dispatch(setFeedback(errPayload));
      }
    },
    [dispatch, formData.assignee, formData.project]
  );

  // useEffect(() => {
  //   fetchTodoHandler();
  //   fetchProjectHandler();
  // }, [fetchTodoHandler, fetchProjectHandler]);

  const resetForm = () => {
    setFormData({
      assignee: null,
      project: null,
      todo: null,
      memo: "",
      date: "",
      from: "",
      to: "",
      reason: null,
      other: "",
    });
    setError({
      assignee: "",
      project: "",
      todo: "",
      memo: "",
      date: "",
      from: "",
      to: "",
      reason: "",
      other: "",
    });
    addModalClose();
  };

  const changeAssigneeHandler = (selectedOption) => {
    if (selectedOption) {
      setFormData((prev) => ({
        ...prev,
        assignee: selectedOption,
        project: null,
      }));
      setError((prev) => ({ ...prev, assignee: "" }));
    }
  };
  const changeProjectHandler = (selectedOption) => {
    if (selectedOption) {
      setFormData((prev) => ({
        ...prev,
        project: selectedOption,
        billable: selectedOption.billable,
      }));
      setError((prev) => ({ ...prev, project: "" }));
    }
  };
  const changeBillableHandler = (value) => {
    setFormData((prev) => ({ ...prev, billable: value }));
  };
  const changeTodoHandler = (selectedOption) => {
    if (selectedOption) {
      setFormData((prev) => ({ ...prev, todo: selectedOption }));
      setError((prev) => ({ ...prev, todo: "" }));
    }
  };
  const changeMemoHandler = (e) => {
    setError((prev) => ({
      ...prev,
      memo: e.target.value !== "" ? "" : "Please enter memo.",
    }));
    setFormData((prev) => ({ ...prev, memo: e.target.value }));
  };
  const changeDateHandler = (date) => {
    if (date === null) {
      setError((prev) => ({ ...prev, date: "Please enter date." }));
    } else {
      setError((prev) => ({ ...prev, date: "" }));
    }
    setFormData((prev) => ({ ...prev, date: date }));
  };
  const changeFromHandler = (time) => {
    if (time === null) {
      setError((prev) => ({ ...prev, from: "Please enter FROM time." }));
      setIsDisableToTime(true);
    } else {
      setError((prev) => ({ ...prev, from: "" }));
      setIsDisableToTime(false);
    }
    setFormData((prev) => ({ ...prev, from: time }));

    if (formData.to) {
      if (time?.$H || time?.$m) {
        let fromMinutes = time.$H * 60 + time.$m;
        let toMinutes = formData.to.$H * 60 + formData.to.$m;
        const totalMinutes = toMinutes - fromMinutes;

        const hours = totalMinutes / 60;
        const rHour = Math.floor(hours);
        const minutes = (hours - rHour) * 60;
        const rMinutes = Math.round(minutes);

        setTotalDuration(
          `${rHour.toString().padStart(2, "0")}:${rMinutes
            .toString()
            .padStart(2, "0")}`
        );
      } else {
        setTotalDuration("00:00");
      }
    }
  };
  const changeToHandler = (time) => {
    if (time === null) {
      setError((prev) => ({ ...prev, to: "Please enter TO time." }));
    } else if (
      !isToTimeValid(
        dayjs(formData.from).format("HH:mm"),
        dayjs(time).format("HH:mm")
      )
    ) {
      setError((prev) => ({ ...prev, to: "TO time is not valid." }));
    } else {
      setError((prev) => ({ ...prev, to: "" }));
    }
    setFormData((prev) => ({ ...prev, to: time }));

    if (formData.from) {
      if (time?.$H || time?.$m) {
        let fromMinutes = formData.from.$H * 60 + formData.from.$m;
        let toMinutes = time.$H * 60 + time.$m;
        const totalMinutes = toMinutes - fromMinutes;

        const hours = totalMinutes / 60;
        const rHour = Math.floor(hours);
        const minutes = (hours - rHour) * 60;
        const rMinutes = Math.round(minutes);

        setTotalDuration(
          `${rHour.toString().padStart(2, "0")}:${rMinutes
            .toString()
            .padStart(2, "0")}`
        );
      } else {
        setTotalDuration("00:00");
      }
    } else {
      setError((prev) => ({ ...prev, from: "Please enter FROM time first." }));
      setFormData((prev) => ({ ...prev, to: null }));
      return;
    }
  };
  const convertToSecondsFormat = (employees) => {
    return employees
      .map((employee) => {
        // Split the alloted_hours into hours and minutes
        const [hours, minutes] = employee.alloted_hours.split(":").map(Number);

        // Convert hours and minutes into seconds
        const totalSeconds = hours * 3600 + minutes * 60;

        // Return the formatted string "id:number_of_seconds"
        return employee?.same_as_tracked?`${employee.id}:auto`:`${employee.id}:${totalSeconds}`;
      })
      .join(",");
  };
  const submitHandler = (e) => {
    e.preventDefault();
    setEmployees(timeData)
    const alloted_data_params = convertToSecondsFormat(timeData);

    // setAllotedData(alloted_data_params)
    allocatedDataRef.current = alloted_data_params;
    try {
      fetchReportData("", alloted_data_params);
      addModalClose();
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    }
  };
  const [timeData, setTimeData] = useState([]);

  useEffect(() => {
    setTimeData(employees);
  }, [employees]);

  // const fetchTimeLineChartData = useCallback(async () => {
  //   try {
  //     const params = {
  //       date: convertDateIntoYYYYMMDD(formData.date),
  //       manual_entry: true,
  //     };
  //     const response = await getTimeLineChartData(
  //       params,
  //       formData.assignee.value
  //     );
  //     if (response.data.status === 200) {
  //       setTimeLineChartData(response.data.data);
  //       setIsAbleToAddEntry(true);
  //     } else if (response.data.status === 404) {
  //       setTimeLineChartData([]);
  //       setIsAbleToAddEntry(true);
  //     } else {
  //       const errPayload = {
  //         code: response.data.status,
  //         status: "fail",
  //         message: response.data.message || "Something went wrong.",
  //       };
  //       dispatch(setFeedback(errPayload));
  //       if (response.data.status === 422) {
  //         setTimeLineChartData([]);
  //         setIsAbleToAddEntry(false);
  //       }
  //     }
  //   } catch (error) {
  //     const errPayload = {
  //       code: error.status,
  //       status: "fail",
  //       message: error.message || "Something went wrong.",
  //     };
  //     dispatch(setFeedback(errPayload));
  //   }
  // }, [formData.assignee, formData.date, dispatch]);

  // useEffect(() => {
  //   fetchTimeLineChartData();
  // }, [fetchTimeLineChartData]);

  // Usage example:
  const selectAssigneeStyles = getSelectStyles(error.assignee);
  const selectProjectStyles = getSelectStyles(error.project);
  const selectTodoStyles = getSelectStyles(error.todo);
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={addTimeModalOpen}
        onClose={resetForm}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={addTimeModalOpen}>
          <Box
            sx={{ maxHeight: "80vh", overflowY: "auto", position: "relative" }}
            className="modal-wrapper modal-bg add-time"
          >
            <Typography
              variant="h6"
              component="h6"
              className="text-black modal-title"
            >
              Edit Allocated Time Of Employees
            </Typography>
            <Box className="modal-close" onClick={resetForm}>
              <FiPlus />
            </Box>

            <Box className="modal-body">
              <TableContainer sx={{ maxHeight: "500px", overflowY: "auto" }}>
                <Table stickyHeader>
                  {/* Table Head */}
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="body2"
                          className="text-black input-label"
                        >
                          Employee Name
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body2"
                          className="text-black input-label"
                        >
                          Allocated Time
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body2"
                          className="text-black input-label"
                        >
                          A = T
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {/* Table Body */}
                  <TableBody>
                    {timeData.map((employee, index) => (
                      <TableRow key={index}>
                        {/* Employee Name */}
                        <TableCell>{employee.employee_name}</TableCell>

                        {/* Allocated Hours Dropdown */}
                        <TableCell>
                          <Box sx={{ display: "flex", gap: "8px" }}>
                            <FormControl variant="standard" fullWidth>
                              <Select
                                options={hourOptions}
                                styles={customSelectStyles}
                                // menuIsOpen={true}
                                menuPortalTarget={document.body}
                                defaultValue={hourOptions.find(
                                  (option) =>
                                    option.value ===
                                    employee.alloted_hours.split(":")[0]
                                )}
                                //   menuIsOpen={true}
                                isDisabled={employee?.same_as_tracked}
                                onChange={(value) => {
                                  setTimeData((state) => {
                                    const indexof = state.findIndex(
                                      (arr) =>
                                        arr.employee_name ===
                                        employee.employee_name
                                    );
                                    const newTimeData = [...state];
                                    // console.log(newTimeData,"newTimeData",newTimeData[indexof].alloted_hours)
                                    newTimeData[indexof] = {
                                      id: newTimeData[indexof].id,
                                      employee_name:
                                        newTimeData[indexof].employee_name,
                                      alloted_hours: `${value.value}:${
                                        newTimeData[
                                          indexof
                                        ].alloted_hours.split(":")[1]
                                      }`,
                                    };
                                    return newTimeData;
                                  });
                                  // setEmployees((state) => {
                                  //   const indexof = state.findIndex(
                                  //     (arr) =>
                                  //       arr.employee_name ===
                                  //       employee.employee_name
                                  //   );
                                  //   const newTimeData = [...state];
                                  //   // console.log(newTimeData,"newTimeData",newTimeData[indexof].alloted_hours)
                                  //   newTimeData[indexof] = {
                                  //     id: newTimeData[indexof].id,
                                  //     employee_name:
                                  //       newTimeData[indexof].employee_name,
                                  //     alloted_hours: `${value.value}:${
                                  //       newTimeData[
                                  //         indexof
                                  //       ].alloted_hours.split(":")[1]
                                  //     }`,
                                  //   };
                                  //   return newTimeData;
                                  // });
                                }}
                              ></Select>
                            </FormControl>
                            <FormControl variant="standard" fullWidth>
                              <Select
                                options={minuteOptions}
                                styles={customSelectStyles}
                                menuPortalTarget={document.body}
                                defaultValue={minuteOptions.find(
                                  (option) =>
                                    option.value ===
                                    employee.alloted_hours.split(":")[1]
                                )}
                                isDisabled={employee?.same_as_tracked}
                                onChange={(value) => {
                                  setTimeData((state) => {
                                    const indexof = state.findIndex(
                                      (arr) =>
                                        arr.employee_name ===
                                        employee.employee_name
                                    );
                                    const newTimeData = [...state];
                                    // console.log(newTimeData,"newTimeData",newTimeData[indexof].alloted_hours)
                                    newTimeData[indexof] = {
                                      id: newTimeData[indexof].id,
                                      employee_name:
                                        newTimeData[indexof].employee_name,
                                      alloted_hours: `${
                                        newTimeData[
                                          indexof
                                        ].alloted_hours.split(":")[0]
                                      }:${value.value}`,
                                    };
                                    return newTimeData;
                                  });
                                  // setEmployees((state) => {
                                  //   const indexof = state.findIndex(
                                  //     (arr) =>
                                  //       arr.employee_name ===
                                  //       employee.employee_name
                                  //   );
                                  //   const newTimeData = [...state];
                                  //   // console.log(newTimeData,"newTimeData",newTimeData[indexof].alloted_hours)
                                  //   newTimeData[indexof] = {
                                  //     id: newTimeData[indexof].id,
                                  //     employee_name:
                                  //       newTimeData[indexof].employee_name,
                                  //     alloted_hours: `${
                                  //       newTimeData[
                                  //         indexof
                                  //       ].alloted_hours.split(":")[0]
                                  //     }:${value.value}`,
                                  //   };
                                  //   return newTimeData;
                                  // });
                                }}
                              ></Select>
                            </FormControl>
                          </Box>
                        </TableCell>

                        {/* Allocated Minutes Dropdown */}
                        <TableCell>
                          <Checkbox
                            // checked={false} // Assuming you have a boolean in `employee` to track this
                            checked={employee?.same_as_tracked ? employee?.same_as_tracked  : false}
                            onChange={(e) => {
                              setTimeData((state) => {
                                const indexof = state.findIndex(
                                  (arr) =>
                                    arr.employee_name ===
                                    employee.employee_name
                                );
                                const newTimeData = [...state];
                                // console.log(newTimeData,"newTimeData",newTimeData[indexof].alloted_hours)
                                newTimeData[indexof] = {
                                  id: newTimeData[indexof].id,
                                  employee_name:
                                    newTimeData[indexof].employee_name,
                                  alloted_hours:  newTimeData[indexof].alloted_hours,
                                  same_as_tracked: e.target.checked,
                                };
                                return newTimeData;
                              });
                              // setEmployees((state) => {
                              //   const indexof = state.findIndex(
                              //     (arr) =>
                              //       arr.employee_name ===
                              //       employee.employee_name
                              //   );
                              //   const newTimeData = [...state];
                              //   // console.log(newTimeData,"newTimeData",newTimeData[indexof].alloted_hours)
                              //   newTimeData[indexof] = {
                              //     id: newTimeData[indexof].id,
                              //     employee_name:
                              //       newTimeData[indexof].employee_name,
                              //     alloted_hours:  newTimeData[indexof].alloted_hours,
                              //     same_as_tracked: e.target.checked,
                              //   };
                              //   return newTimeData;
                              // });
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box
              className="modal-footer"
              style={{ marginTop: "30px" }}
              sx={{ position: "sticky", bottom: 0, zIndex: 1300 }}
            >
              <Grid container spacing={3}>
                <Grid item md={6} xs={6}>
                  <Button
                    className="btn btn-primary"
                    onClick={submitHandler}
                    // disabled={!isAbleToAddEntry}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item md={6} xs={6}>
                  <Button className="btn btn-secondary" onClick={resetForm}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default EditAllocatedTime;
