import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { DATE_RANGE_TYPE } from "../lib/constants";
import Cookies from "js-cookie";
const USER_DATA_COOKIE = process.env.REACT_APP_USER_DATA_COOKIE;

export const convertDateIntoDDMMYYYY = (date) => {
  return dayjs(date).locale("en").format("DD-MM-YYYY");
};
export const convertDateIntoDDMM = (date) => {
  return `${dayjs(date).locale("en").format("DD-MM-YYYY").split('-')[0]}-${dayjs(date).locale("en").format("DD-MM-YYYY").split('-')[1]}`;
};
export const convertDateIntoYYYYMMDD = (date) => {
  return dayjs(date).locale("en").format("YYYY-MM-DD");
};

export const convertTimeInto24HoursFormat = (date) => {
  const utcDate = new Date(date);
  const hours = utcDate.getUTCHours();
  const minutes = utcDate.getUTCMinutes();
  const seconds = utcDate.getUTCSeconds();

  return `${hours > 12 ? hours - 12 : hours}:${
    minutes < 10 ? "0" : ""
  }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

export const convertTimeInto12HoursFormat = (date, flag = 0) => {
  if (flag === 0) {
    const utcDate = new Date(date);
    const hours = utcDate.getUTCHours();
    const minutes = utcDate.getUTCMinutes();
    const seconds = utcDate.getUTCSeconds();

    return `${hours > 12 ? hours - 12 : hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}:${seconds < 10 ? "0" : ""}${seconds} ${
      hours >= 12 ? "pm" : "am"
    }`;
  } else {
    const utcDate = new Date(date);
    const hours = utcDate.getUTCHours();
    const minutes = utcDate.getUTCMinutes();
    return `${hours > 12 ? hours - 12 : hours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${hours >= 12 ? "pm" : "am"}`;
  }
};

export const convertSecondsIntoTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
};

export const convertSecondsIntoHHMMSS = (milliseconds) => {
  //   const seconds = parseInt(secondsValue);
  //   const hours = Math.floor(seconds / 3600);
  //   const minutes = Math.floor((seconds % 3600) / 60);

  //   const HH = hours < 10 ? `0${hours}` : hours.toString();
  //   const MM = minutes < 10 ? `0${minutes}` : minutes.toString();

  //   return `${HH}:${MM}`;
  // };

  // export const truncateString = (string, maxLength) => {
  //   if (string?.length > maxLength) {
  //     return string.slice(0, maxLength) + "...";
  //   }
  //   return string;
  // Convert milliseconds to seconds
  let seconds = Math.floor(milliseconds / 1000);

  // Calculate hours, minutes, and remaining seconds
  let hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  let minutes = Math.floor(seconds / 60);
  seconds %= 60;

  // Format the result
  let hms = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

  return hms;
};
export const convertSecondsIntoHHMM = (secondsValue) => {
  const seconds = parseInt(secondsValue);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const HH = hours < 10 ? `0${hours}` : hours.toString();
  const MM = minutes < 10 ? `0${minutes}` : minutes.toString();

  return `${HH}:${MM}`;
};

export const truncateString = (string, maxLength) => {
  if (string?.length > maxLength) {
    return string.slice(0, maxLength) + "...";
  }
  return string;
};

export const formatPrintMemo = (memo) => {
  const lines = memo.split("\n").map((line) => {
    return (
      <>
        {line} <br />
      </>
    );
  });
  return lines;
};

export const handleLabel2 = (is, value) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "6px",
      }}
    >
      <p
        className="dot"
        style={{
          width: "11px",
          height: "10px",
          backgroundColor: is ? "#8CC714" : "#323232",
          borderRadius: "10px",
        }}
      ></p>
      <Typography style={{ fontSize: "14px" }}>{value}</Typography>
    </div>
  );
};

export const handleLabel = (is, value, label) => {
  // return { value: value, label: label, dot_color: is ? '#8CC714' : '#777777', text_color: is ? '' : '#777777'  }
  return { value: value, label: label, dot_color: is ? "#8CC714" : "#777777" };
};

export const getSelectStyles = (isValidate) => {
  const baseStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: !isValidate ? "#cccccc" : "red",
      minHeight: "50px",
    }),
  };

  const theme = (theme) => ({
    ...theme,
    borderRadius: 10,
    colors: {
      ...theme.colors,
      primary25: "rgba(140, 199, 20, 0.4)",
      primary: !isValidate ? "#8CC714" : "red",
    },
  });
  return {
    styles: baseStyles,
    theme: theme,
  };
};

export const getDateRangeOptions = () => {
  const today = new Date();

  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const lastMonthStartDate = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1
  );
  const lastMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
  const thisMonthStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastWeekStartDate = new Date(
    today.getTime() - (today.getDay() + 7) * 24 * 60 * 60 * 1000
  );
  const lastWeekEndDate = new Date(
    today.getTime() - (today.getDay() + 1) * 24 * 60 * 60 * 1000
  );
  const firstDayOfWeek = today.getDate() - today.getDay();
  const thisWeekStartDate = new Date(today.setDate(firstDayOfWeek));
  const thisWeekEndDate = new Date();

  const ranges = [
    {
      label: DATE_RANGE_TYPE.today,
      value: [new Date(), new Date()],
    },
    {
      label: DATE_RANGE_TYPE.yesterday,
      value: [yesterday, yesterday],
    },
    {
      label: DATE_RANGE_TYPE.thisWeek,
      value: [thisWeekStartDate, thisWeekEndDate],
    },
    {
      label: DATE_RANGE_TYPE.lastWeek,
      value: [lastWeekStartDate, lastWeekEndDate],
    },
    {
      label: DATE_RANGE_TYPE.last7Days,
      value: [
        new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
        new Date(),
      ],
    },
    {
      label: DATE_RANGE_TYPE.thisMonth,
      value: [thisMonthStartDate, new Date()],
    },
    {
      label: DATE_RANGE_TYPE.lastMonth,
      value: [lastMonthStartDate, lastMonthEndDate],
    },
    {
      label: DATE_RANGE_TYPE.last30Days,
      value: [
        new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
        new Date(),
      ],
    },
  ];

  return ranges;
};

export const getDateValueBasedOnDateType = (
  type,
  start_date = null,
  end_date = null
) => {
  const today = new Date();

  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const lastMonthStartDate = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1
  );
  const lastMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
  const thisMonthStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastWeekStartDate = new Date(
    today.getTime() - (today.getDay() + 7) * 24 * 60 * 60 * 1000
  );
  const lastWeekEndDate = new Date(
    today.getTime() - (today.getDay() + 1) * 24 * 60 * 60 * 1000
  );
  const firstDayOfWeek = today.getDate() - today.getDay();
  const thisWeekStartDate = new Date(today.setDate(firstDayOfWeek));
  const thisWeekEndDate = new Date();

  switch (type) {
    case DATE_RANGE_TYPE.today:
      return [new Date(), new Date()];
    case DATE_RANGE_TYPE.yesterday:
      return [yesterday, yesterday];
    case DATE_RANGE_TYPE.thisWeek:
      return [thisWeekStartDate, thisWeekEndDate];
    case DATE_RANGE_TYPE.lastWeek:
      return [lastWeekStartDate, lastWeekEndDate];
    case DATE_RANGE_TYPE.last7Days:
      return [
        new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
        new Date(),
      ];
    case DATE_RANGE_TYPE.thisMonth:
      return [thisMonthStartDate, new Date()];
    case DATE_RANGE_TYPE.lastMonth:
      return [lastMonthStartDate, lastMonthEndDate];
    case DATE_RANGE_TYPE.last30Days:
      return [
        new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
        new Date(),
      ];

    case DATE_RANGE_TYPE.custom:
      return [new Date(start_date), new Date(end_date)];

    default:
      return [new Date(), new Date()];
  }
};

export const dot = (color = "transparent", marginRight, position) => ({
  alignItems: "center",
  justifyContent: "start",
  display: "flex",
  paddingLeft: "14px",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: marginRight,
    height: 10,
    width: 10,
    position: position,
    left: "8px",
  },
});

export const getDotColorStyle = (tag) => {
  if (tag === "SINGLE") {
    const colorStyles = {
      control: (styles) => ({ ...styles, backgroundColor: "white" }),
      option: (styles, { data }) => {
        return { ...styles, wordBreak: "break-word" };
      },
      // multiValueLabel: (styles, {data}) => ({ ...styles, ...dot(data.color) }),
      // multiValue: (styles, {data}) => ({ ...styles, ...dot(data.color) }),
      singleValue: (styles, { data }) => ({
        ...styles,
        ...dot(data.dot_color, 8, "absolute"),
      }),
    };
    return colorStyles;
  } else if (tag === "MULTI") {
    const colorStyles = {
      multiValue: (styles, { data }) => ({
        ...styles,
        backgroundColor: "rgba(140, 199, 20, 0.2)",
        borderRadius: "10px",
        border: "1px solid #8CC714",
        paddingLeft: "8px",
        ...dot(data.dot_color, 0, "static"),
      }),
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        fontSize: "16px",
      }),
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: "#cccccc",
        minHeight: "50px",
      }),

      // control: (styles) => ({...styles, backgroundColor:'white' }),
      option: (styles, { data }) => {
        return { ...styles };
      },
      // multiValueLabel: (styles, {data}) => ({ ...styles, ...dot(data.color) }),
      // multiValue: (styles, {data}) => ({ ...styles, ...dot(data.color) }),
      // singleValue: (styles, { data }) => ({ ...styles, ...dot(data.dot_color) }),
    };
    return colorStyles;
  }
};
export const getNormalColorStyle = (tag) => {
  if (tag === "SINGLE") {
    const colorStyles = {
      control: (provided, state) => ({
        ...provided,
        borderColor: "#cccccc",
        minHeight: "50px",
      }),
    };
    return colorStyles;
  } else if (tag === "MULTI") {
    const colorStyle = {
      multiValue: (styles, { data }) => ({
        ...styles,
        backgroundColor: "rgba(140, 199, 20, 0.2)",
        borderRadius: "6px",
        border: "1px solid #8CC714",
      }),
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        fontSize: "16px",
      }),
      control: (baseStyles, state) => ({
        ...baseStyles,
        minHeight: "50px",
      }),
    };
    return colorStyle;
  }
};

export const getLocalTimeZone = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timeZone;
};

// Store Filter Values and Preserve it
export const setFilterStorage = (storageName, filter) => {
  localStorage.setItem(storageName, JSON.stringify(filter));
};
export const getFilterStorage = (storageName) => {
  const filters = JSON.parse(localStorage.getItem(storageName));
  return filters;
};
export const removeFilterStorage = (storageName) => {
  localStorage.removeItem(storageName);
};

// Access rights of permissions
export const getPermissionAccess = (permissions, module) => {
  const modulePermission = permissions.find(
    (row) => row.module_name === module
  );

  let permission = { add: false, edit: false, delete: false, view: false };
  if (modulePermission.permissions === "full") {
    permission.add = true;
    permission.edit = true;
    permission.delete = true;
    permission.view = true;
  } else if (modulePermission.permissions === "add_update") {
    permission.add = true;
    permission.edit = true;
    permission.view = true;
  } else if (modulePermission.permissions === "view") {
    permission.view = true;
  } else if (modulePermission.permissions === "none") {
    permission.add = false;
    permission.edit = false;
    permission.delete = false;
    permission.view = false;
  }
  return permission;
};

export const getUserProfileDataFromCookies = () => {
  const userData = Cookies.get(USER_DATA_COOKIE);

  if (!userData) {
    return null;
  }

  const jsonData = userData?.substring(2);
  const finalUserData = JSON.parse(jsonData);
  return finalUserData;
};
