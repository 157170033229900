import { FETCH_CLIENTS } from "../redux/actionTypes";
import { takeEvery, put } from "redux-saga/effects";
import {
  ADD_PROJECT,
  ADD_PROJECT_SUCCESS,
  CHANGE_PROJECT_STATUS,
  CHANGE_PROJECT_STATUS_SUCCESS,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  FETCH_MEMBERS,
  FETCH_PROJECTS,
  LEAVE_PROJECT,
  SET_FEEDBACK,
  SET_LOADING,
  STORE_MEMBERS,
  STORE_PROJECTS,
  STORE_CLIENTS,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
} from "../redux/actionTypes";
import {
  createProject,
  deleteProjectById,
  fetchAllMembers,
  fetchProjectsByUserId,
  leaveProjectById,
  updateProject,
} from "../../apis/project";
import { showSuccessToast } from "../../utils/toast";
import { fetchClientsByUserId } from "../../apis/client";
function* fetchClientHandler(action) {
   
    try {
      yield put({ type: SET_LOADING, payload: true });
      const params = action.payload;
  
      const isListing = params.isListing;
      params.isListing && delete params.isListing;
  
      const response = yield fetchClientsByUserId(params);
  
      yield put({ type: SET_LOADING, payload: false });
      if (params?.setprojectsFetched) {
        params?.setprojectsFetched(true);
      }
      if (response.data.status === 200) {
        if (!isListing) {
          const payload = response.data.data;
  
          yield put({ type: STORE_CLIENTS, payload });
        } else {
          console.log("response data as given",response)
          const payload = { clients_list : response.data.data };
          yield put({ type: STORE_CLIENTS, payload });
        }
      } else if (response.data.status === 404) {
        yield put({
          type: STORE_CLIENTS,
          payload: {
            projects_list: [],
            projects: [],
            activeProjectCount: 0,
            inactiveProjectCount: 0,
            totalCount: 0,
          },
        });
      } else {
        const payload = {
          code: response.data.status,
          status: "fail",
          message: response.data.message || "Something went wrong.",
        };
        yield put({ type: SET_FEEDBACK, payload });
      }
    } catch (error) {
      yield put({ type: SET_LOADING, payload: false });
      const payload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      yield put({ type: SET_FEEDBACK, payload });
    }
  }
function* watchClientAction() {
    yield takeEvery(FETCH_CLIENTS, fetchClientHandler);

  }
  export default watchClientAction;