import {
    GET_USER_LIST_START,
    GET_USER_LIST_SUCCESS,
    GET_USER_LIST_FAILURE,
    FETCH_USER_LIST_STORE,
  } from '../actionTypes';
  
  export const getUserListStart = () => ({
    type: GET_USER_LIST_START,
  });
  
  export const getUserListSuccess = (userList) => ({
    type: GET_USER_LIST_SUCCESS,
    payload: userList,
  });
  
  export const getUserListFailure = (error) => ({
    type: GET_USER_LIST_FAILURE,
    payload: error,
  });
  
  export const fetchUserListStore = (token) => ({
    type: FETCH_USER_LIST_STORE,
    payload: token,
  });