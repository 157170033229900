import {
    GET_USER_LIST_START,
    GET_USER_LIST_SUCCESS,
    GET_USER_LIST_FAILURE,
  } from '../actionTypes';
  
  const initialState = {
    userList: null,
    loading: false,
    error: null,
  };
  
  const userListReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_USER_LIST_START:
        return { ...state, loading: true, error: null };
      case GET_USER_LIST_SUCCESS:
        return { ...state, loading: false, userList: action.payload };
      case GET_USER_LIST_FAILURE:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export default userListReducer;