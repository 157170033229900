import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Tooltip } from "@mui/material";

const ClientName = ({ module, ProjectInitial, ProjectTitle }) => {
  return (
    <Box className="project-name">
      {module === "report" ? (
        <Tooltip
          title={
            <Typography sx={{ fontSize: "12px" }}>
              {" "}
              {/* Adjust font size here */}
              {ProjectTitle}
            </Typography>
          }
          placement="right"
        >
          <Typography variant="h3" component="h3" className="text-grey">
            {ProjectTitle.length < 18
              ? ProjectTitle
              : ProjectTitle.slice(0, 15) + "..."}
          </Typography>
        </Tooltip>
      ) : (
        <Typography variant="h3" component="h3" className="text-grey">
          {ProjectTitle}
        </Typography>
      )}
    </Box>
  );
};

export default ClientName;
