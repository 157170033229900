import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Slider from "react-slick";
import { Checkbox, FormControlLabel, styled } from "@mui/material";
import { FiDownloadCloud, FiPlus } from "react-icons/fi";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const BASE_PATH2 = process.env.REACT_APP_IMAGE_PATH2;
// const DRIVE_PATH = "https://drive.google.com/uc?id=<img-id>&export=view";
const DRIVE_PATH = "https://drive.google.com/thumbnail?id=<img-id>&sz=w1000"; // quality image path

function formatslotText(timeString) {
  const utcDate = new Date(timeString);
  const hours = utcDate.getUTCHours();
  const minutes = utcDate.getUTCMinutes();

  return `${hours > 12 ? hours - 12 : hours}:${
    minutes < 10 ? "0" : ""
  }${minutes} ${hours >= 12 ? "PM" : "AM"}`;
}

const getFullImagePath = (driveScreenShot, screenShot) => {
  let image = null;

  if (driveScreenShot) {
    image = DRIVE_PATH.replace("<img-id>", driveScreenShot);
    return image;
  } else if (screenShot) {
    image = BASE_PATH2 + screenShot;
    return image;
  }
  return image;
};

const ImageModal = ({
  imageModalOpen,
  moveSlotArray = [],
  Slot = {},
  currentRow,
  selectAllScreenShotHandler,
  checkBoxHandler,
  closeImageModalHandler,
  finalModalArray,
  imageIndex,
  userName,
}) => {
  const [currentIndex, setCurrentIndex] = useState(imageIndex);
  const sliderRef = useRef(null);
  const [currentImage, setcurrentImage] = useState(finalModalArray[imageIndex]);
  const [currentSlot, setcurrentSlot] = useState(Slot);
  const [downloadButtonClicked, setDownloadButtonClicked] = useState(false);
  const [currentImageSrc] = useState("");

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    lazyLoad: "ondemand",
    initialSlide: imageIndex,
    beforeChange: (oldIndex, newIndex) => {
      // Update the currentSlot when the slide changes
      const img = finalModalArray[newIndex];
      const imgSlot = {
        start_date: img?.start_date,
        end_date: img?.end_date,
      };
      setcurrentSlot(imgSlot);
      setcurrentImage(finalModalArray[newIndex]);
      setCurrentIndex(newIndex); // Update the index in case it's needed elsewhere
    },
  };

  const downloadImage = (index, imageType) => {
    const img = finalModalArray[index];

    let blocktimeText;
    if (img.blocktime) {
      blocktimeText = img.blocktime;
    } else if (img.start_date && img.end_date) {
      blocktimeText = `${formatslotText(img.start_date)} - ${formatslotText(
        img.end_date
      )}`;
    }

    const primaryImagePath = getFullImagePath(
      img.drive_primary,
      img.screenshot_primary
    );
    const secondaryImagePath = getFullImagePath(
      img.drive_secondary,
      img.screenshot_secondary
    );

    const imageUrl =
      imageType === "primary" ? primaryImagePath : secondaryImagePath;

    const formattedBlocktimeText = blocktimeText.replace(/:/g, "-");

    const filename =
      imageType === "primary"
        ? `${userName}_${formattedBlocktimeText}_primary_screenshot`
        : `${userName}_${formattedBlocktimeText}_secondary_screenshot`;

    const link = document.createElement("a");
    link.href = imageUrl.replace("view", "download");
    link.download = filename;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  const handleDownloadClick = (imageType, index) => {
    if (!downloadButtonClicked) {
      setDownloadButtonClicked(true);
      downloadImage(index, imageType);
    }
  };

  useEffect(() => {
    if (finalModalArray[imageIndex]) {
      const img = finalModalArray[imageIndex];
      const imgSlot = {
        start_date: img?.start_date,
        end_date: img?.end_date,
      };
      setcurrentSlot(imgSlot);
    }
  }, [imageIndex, finalModalArray]);

  const previousClick = () => {
    let newIndex = currentIndex - 1;
    setCurrentIndex(newIndex);
    // if (newIndex < 0) {
    //   newIndex = finalModalArray.length - 1;
    // }
    // setcurrentSlot({
    //   start_date: finalModalArray[newIndex]?.start_date,
    //   end_date: finalModalArray[newIndex]?.end_date,
    // });
    // setcurrentImage(finalModalArray[newIndex]);
    sliderRef.current.slickPrev();
    setDownloadButtonClicked(false);
  };

  const nextClick = () => {
    let newIndex = currentIndex + 1;
    setCurrentIndex(newIndex);
    // if (newIndex >= finalModalArray.length) {
    //   newIndex = 0;
    // }

    // setcurrentSlot({
    //   start_date: finalModalArray[newIndex]?.start_date,
    //   end_date: finalModalArray[newIndex]?.end_date,
    // });
    // setcurrentImage(finalModalArray[newIndex]);
    sliderRef.current.slickNext();
    setDownloadButtonClicked(false);
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "ArrowLeft") {
        previousClick();
      } else if (e.key === "ArrowRight") {
        nextClick();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={imageModalOpen}
        onClose={closeImageModalHandler}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={imageModalOpen}>
          <Box sx={styled} className="modal-wrapper modal-screencast-slider">
            <Box
              className="modal-close"
              onClick={closeImageModalHandler}
              //   sx={{ border: "2px solid blue" }}
            >
              <FiPlus />
            </Box>

            <Slider ref={sliderRef} {...settings}>
              {finalModalArray?.map((img, index) => {
                // console.log("img", img);
                const imgSlot = {
                  end_date: img?.end_date,
                  start_date: img?.start_date,
                };

                // setcurrentSlot(imgSlot);
                // console.log("imgSlot", imgSlot);
                const imagesToDisplay = [];
                const PRIMARY_IMAGE = getFullImagePath(
                  img.drive_primary,
                  img.screenshot_primary
                );
                const SECONDARY_IMAGE = getFullImagePath(
                  img.drive_secondary,
                  img.screenshot_secondary
                );
                let blocktimeText;

                if (img.blocktime) {
                  blocktimeText = img.blocktime;
                } else if (img.start_date && img.end_date) {
                  blocktimeText = `${formatslotText(
                    img.start_date
                  )} - ${formatslotText(img.end_date)}`;
                }

                if (PRIMARY_IMAGE !== null && PRIMARY_IMAGE !== "") {
                  // setcurrentImage(img);
                  imagesToDisplay.push(
                    <Box className="image-wrapper">
                      <div style={{ position: "relative" }}>
                        <Box
                          className="download-screencast"
                          onClick={() => handleDownloadClick("primary", index)}
                        >
                          <FiDownloadCloud />
                        </Box>
                        <img
                          src={PRIMARY_IMAGE}
                          key={index + "_primary"}
                          className="screencast-img"
                          alt="screencast"
                          loading="lazy"
                        />
                        <Box className="modal-image-check">
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  // paddingRight: "5px",
                                  color: "#8cc714", // Unchecked color
                                  "&.Mui-checked": {
                                    color: "#8cc714", // Checked color
                                  },
                                }}
                                className="time-slot-checkbox billable-slot-checkbox"
                                size="small"
                                checked={
                                  moveSlotArray?.some(
                                    (e) =>
                                      e.start_date === imgSlot?.start_date &&
                                      e.end_date === imgSlot?.end_date
                                  ) === true &&
                                  img.start_date === imgSlot?.start_date &&
                                  img.end_date === imgSlot?.end_date
                                    ? true
                                    : false
                                }
                              />
                            }
                            sx={{
                              marginRight: 0,
                              marginLeft: 0,
                            }}
                            // label="Billable"
                            // classes={{ label: 'custom-label' }}
                            onChange={(e) =>
                              checkBoxHandler(e, currentSlot, currentRow)
                            }
                          />
                        </Box>
                      </div>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          //   border: "2px solid green",
                        }}
                      >
                        <Typography className="screen-name">
                          Screen 1
                        </Typography>

                        <Typography className="screen-name">
                          {blocktimeText} ({img.overall_activity}% Activity)
                        </Typography>
                      </Box>
                    </Box>
                  );
                }

                if (SECONDARY_IMAGE !== null && SECONDARY_IMAGE !== "") {
                  // setcurrentImage(img);
                  imagesToDisplay.push(
                    <Box className="image-wrapper">
                      <Box
                        className="download-screencast"
                        onClick={() => handleDownloadClick("secondary", index)}
                      >
                        <FiDownloadCloud />
                      </Box>
                      <img
                        src={SECONDARY_IMAGE}
                        key={index + "_secondary"}
                        className="screencast-img"
                        alt="screencast"
                        loading="lazy"
                      />
                      <Typography className="screen-name">Screen 2</Typography>
                      <Typography className="screen-name">
                        {blocktimeText} ({img.overall_activity}% Activity)
                      </Typography>
                    </Box>
                  );
                }

                return imagesToDisplay;
              })}
            </Slider>

            <Box className="custom-slider-buttons">
              <button className="custom-prev-button" onClick={previousClick}>
                <KeyboardArrowLeftIcon />
              </button>
              <button className="custom-next-button" onClick={nextClick}>
                <KeyboardArrowRightIcon />
              </button>
            </Box>
          </Box>
        </Fade>
      </Modal>

      <img
        src={currentImageSrc}
        alt="Hidden"
        style={{ display: "none" }}
        onLoad={() => URL.revokeObjectURL(currentImageSrc)}
      />
    </>
  );
};

export default ImageModal;
