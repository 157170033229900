import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Box,
  Chip,
  Tooltip,
  Typography,
  Avatar,
  Divider,
  Stack,
  IconButton,
  Tabs,
  Tab,
} from "@mui/material";
import CampaignIcon from "@mui/icons-material/Campaign";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import debounce from "lodash/debounce";
import axios from "axios";
import { callApi } from "./announcementApi";
import Cookies from "js-cookie";
import { GoDotFill } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import AnnouncementModal2 from "../../components/common/announcementModel2";
import {
  setTotalAnnouncements,
  unsetMarkedAsReadId,
} from "../../store/redux/actions/announcement.action";
const AnnouncementCard = ({
  id,
  title,
  date,
  description,
  authorName,
  is_priority,
  read,
  setAnnouncementList,
}) => {
  const dispatch = useDispatch();
  const [announcement, setAnnouncement] = useState("");
  const [viewAnnouncementOpen, setViewAnnouncementOpen] = useState(false);
  const userId = Cookies.get(process.env.REACT_APP_USER_ID_COOKIE);
  const showAllChecked = useSelector(
    (state) => state.announcements.showAllChecked
  );

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };

  const extractTextFromHTML = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  return (
    <>
      <Box padding={"16px !important"} position={"relative"}>
        <Stack direction="row" spacing={2} alignItems="center">
          {!showAllChecked && !read ? (
            <GoDotFill fill="#7cb900"></GoDotFill>
          ) : null}
          <Avatar sx={{ backgroundColor: "#bdbdbd" }}>
            <CampaignIcon sx={{ fill: "black" }} />
          </Avatar>
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={async () => {
                try {
                  setViewAnnouncementOpen(true);
                  setAnnouncement({
                    profileImage: <CampaignIcon />,
                    name: title,
                    date: date,
                    content: description,
                    authorName: authorName,
                  });

                  await axios.patch(
                    `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/${userId}/read`,
                    {
                      announcement_id: [id],
                      is_all_read: false,
                    }
                  );
                  const response = await callApi({
                    method: "get",
                    url: `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/module/1`,
                  });

                  dispatch(setTotalAnnouncements(response?.data?.unreadCount));
                  setAnnouncementList((prevList) =>
                    prevList.map((ann) => {
                      if (ann.id === id) {
                        return { ...ann, read: true };
                      }
                      return ann;
                    })
                  );
                } catch (error) {
                  console.error("Failed to mark announcement as read:", error);
                }
              }}
            >
              {title}
            </Typography>
            <Typography variant="body2" sx={{ color: "#232323" }}>
              {truncateText(extractTextFromHTML(description), 60)}
            </Typography>
            <Box display="flex" alignItems="center" mt={1} gap={1}>
              <Typography
                variant="caption"
                display="block"
                sx={{ fontWeight: "bold", mt: 1 }}
              >
                {date}
              </Typography>
              {is_priority && (
                <Chip
                  label="Important"
                  className="important-text"
                  size="small"
                />
              )}
            </Box>
          </Box>

          <IconButton
            sx={{ alignSelf: "flex-start", flexShrink: 0 }}
            onClick={async () => {
              try {
                setViewAnnouncementOpen(true);
                setAnnouncement({
                  profileImage: <CampaignIcon />,
                  name: title,
                  date: date,
                  content: description,
                  authorName: authorName,
                });

                await axios.patch(
                  `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/${userId}/read`,
                  {
                    announcement_id: [id],
                    is_all_read: false,
                  }
                );
                const response = await callApi({
                  method: "get",
                  url: `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/module/1`,
                });

                dispatch(setTotalAnnouncements(response?.data?.unreadCount));
                setAnnouncementList((prevList) =>
                  prevList.map((ann) => {
                    if (ann.id === id) {
                      return { ...ann, read: true };
                    }
                    return ann;
                  })
                );
              } catch (error) {
                console.error("Failed to mark announcement as read:", error);
              }
            }}
          >
            <VisibilityIcon />
          </IconButton>
        </Stack>
      </Box>
      <Divider
        sx={{
          width: "100%",
          padding: "0px !important",
          height: "0px !important",
        }}
      />

      <AnnouncementModal2
        announcement={announcement}
        viewAnnouncementOpen={viewAnnouncementOpen}
        setViewAnnouncementOpen={setViewAnnouncementOpen}
      />
    </>
  );
};

const Announcements = ({
  filterYear,
  filterType,
  filterPostedBy,
  filterMonth,
  searchText,
  status,
  dateFilter,
  setStatus,
}) => {
  const [announcementList, setAnnouncementList] = useState([]);
  const [page, setPage] = useState(1);
  const pageRef = useRef(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const userId = Cookies.get(process.env.REACT_APP_USER_ID_COOKIE);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "do MMMM yyyy", { locale: enGB });
  };
  const dispatch = useDispatch();
  const fetchAllAnnouncements = useCallback(async () => {
    if (loading & !hasMore) {
      return;
    }
    setLoading(true);
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/`,
        params: {
          module_id: 1,
          selected_year: filterYear,
          selected_month: filterMonth,
          posted_by: filterPostedBy,
          type: filterType,
          limit: 20,
          page: pageRef.current,
          dateRange: dateFilter ? `${dateFilter[0]},${dateFilter[1]}` : "",
          searchText: searchText,
        },
      });
      if (response?.data?.allAnnouncements?.length < 20) {
        setHasMore(false);
      }
      const newAnnouncements = response?.data?.allAnnouncements?.map((announcement) => ({
        id: announcement.id,
        title: announcement.title,
        date: formatDate(announcement.createdAt),
        description: announcement.description,
        authorName: `${announcement?.authorInfo?.first_name} ${announcement?.authorInfo?.last_name}`,
        is_priority: announcement.is_priority,
        read: announcement.is_read.includes(+userId),
      }));
      setAnnouncementList((prevAnnouncements) => {
        const existingIds = new Set(prevAnnouncements?.map((a) => a.id));

        const uniqueNewAnnouncements = newAnnouncements?.filter(
          (a) => !existingIds.has(a.id)
        );

        return [...prevAnnouncements, ...uniqueNewAnnouncements];
      });
    } catch (error) {
      console.error("Error fetching announcements:", error);
    } finally {
      setLoading(false);
    }
  }, [
    filterYear,
    filterType,
    filterPostedBy,
    filterMonth,
    pageRef.current,
    dateFilter,
    searchText,
  ]);
  const markedAsReadId = useSelector(
    (state) => state.announcements.markedAsReadId
  );
  useEffect(() => {
    if (markedAsReadId) {
      setAnnouncementList((prevList) =>
        prevList.map((ann) => {
          if (ann.id === markedAsReadId) {
            return { ...ann, read: true };
          }
          return ann;
        })
      );
      dispatch(unsetMarkedAsReadId());
    }
  }, [markedAsReadId]);
  useEffect(() => {
    setAnnouncementList([]);
    setPage(1);
    pageRef.current = 1;
    setHasMore(true);
  }, [
    filterYear,
    filterType,
    filterPostedBy,
    filterMonth,
    searchText,
    dateFilter,
  ]);

  useEffect(() => {
    fetchAllAnnouncements();
  }, [fetchAllAnnouncements]);
  
  const handleScroll = debounce((e) => {
    const container = e.target;
    if (
      container.scrollHeight - container.scrollTop <=
        container.clientHeight + 1 &&
      !loading &&
      hasMore
    ) {
      setPage((prevPage) => prevPage + 1);
      pageRef.current += 1;
    }
  }, 100);
  const tabLabel = (role, modules) => {
    return (
      <Box>
        <Typography variant="body1" component="span" className="user-name">
          {role}
        </Typography>{" "}
      </Box>
    );
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setStatus(newValue);
  };
  return (
    <>
      <Box sx={{ height: "100%", maxHeight: "100%" }}>
        {announcementList?.length > 0 ? (
          <Box
            className="announcement-card-wrapper"
            style={{
              backgroundColor: "white",
              borderRadius: "12px !important",
            }}
            sx={{
              overflowY: "auto",
              backgroundColor: "#f5f5f5",
              padding: "0px !important",
              maxHeight: "70vh",
            }}
            onScroll={handleScroll}
          >
            {announcementList.map((announcement, index) => (
              <AnnouncementCard
                key={index}
                id={announcement.id}
                authorName={announcement.authorName}
                title={announcement.title}
                date={announcement.date}
                description={announcement.description}
                is_priority={announcement.is_priority}
                read={announcement.read}
                setAnnouncementList={setAnnouncementList}
              />
            ))}
          </Box>
        ) : (
          <Box
            className="announcement-card-wrapper"
            style={{
              backgroundColor: "white",
              borderRadius: "12px !important",
            }}
            sx={{
              overflowY: "auto",
              backgroundColor: "#f5f5f5",
              padding: "0px !important",
              height: "70vh",
              maxHeight: "70vh",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "65vh",
                textAlign: "center",
              }}
            >
              No announcements found.
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Announcements;
