/* eslint-disable no-unused-vars */
// import { setFeedback } from "../../store/slices/feedbackSlice";
import axios from "axios";
import Cookies from "js-cookie";
import store from "../../store/index";
axios.defaults.withCredentials = true;

const getTokenFromCookies = Cookies.get(process.env.REACT_APP_COOKIE_NAME);

export const callApi = async (config) => {
  const { url, method, data } = config;
  const state = store.getState();
  const token = getTokenFromCookies;
  // const token = state.auth.token; // Assuming your auth state holds the token

  try {
    const response = await axios({
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    const { message, response } = error;
    // store.dispatch(
    //   setFeedback({
    //     status: "fail",
    //     message: response?.data?.message || message,
    //     code: response?.status || null,
    //   })
    // );

    throw error;
  }
};
