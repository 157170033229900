import {
    ADD_PROJECT_SUCCESS,
    CHANGE_PROJECT_STATUS_SUCCESS,
    DELETE_PROJECT_SUCCESS,
    STORE_MEMBERS,
    STORE_PROJECTS,
    STORE_CLIENTS,
    UPDATE_PROJECT_SUCCESS,
  } from "../actionTypes";
  
  const initialState = {
    clients_list: [],
    projects: [],
    activeProjectCount: 0,
    inactiveProjectCount: 0,
    totalCount: 0,
    members: {},
  };
  
  const ClientReducer = (state = initialState, action) => {
    switch (action.type) {
      case STORE_CLIENTS:
        return {
          ...state,
          ...action.payload,
        };
  
      case ADD_PROJECT_SUCCESS:
        let updatedState;
        if (action.payload.flag === "active") {
          updatedState = {
            ...state,
            activeProjectCount: state.activeProjectCount + 1,
            projects: [action.payload.data, ...state.projects],
          };
        } else if (action.payload.flag === "inactive") {
          updatedState = {
            ...state,
            activeProjectCount: state.activeProjectCount + 1,
            projects: [...state.projects],
          };
        }
        return updatedState;
  
      case UPDATE_PROJECT_SUCCESS:
        return {
          ...state,
          projects: state.projects.map((row) => {
            return row.id === action.payload.id ? { ...action.payload } : row;
          }),
        };
  
      case DELETE_PROJECT_SUCCESS:
        const { ProjectId, flag } = action.payload;
        return {
          ...state,
          activeProjectCount:
            flag === "active"
              ? state.activeProjectCount - 1
              : state.activeProjectCount,
          inactiveProjectCount:
            flag === "inactive"
              ? state.inactiveProjectCount - 1
              : state.inactiveProjectCount,
          projects: state.projects.filter((project) => project.id !== ProjectId),
          totalCount: state.totalCount - 1,
        };
  
      case CHANGE_PROJECT_STATUS_SUCCESS:
        let updatedInactiveCounts;
        let updatedActiveCounts;
        if (action.payload.status === "inactive") {
          updatedInactiveCounts = state.inactiveProjectCount + 1;
          updatedActiveCounts = state.activeProjectCount - 1;
        } else if (action.payload.status === "active") {
          updatedInactiveCounts = state.inactiveProjectCount - 1;
          updatedActiveCounts = state.activeProjectCount + 1;
        }
        return {
          ...state,
          activeProjectCount: updatedActiveCounts,
          inactiveProjectCount: updatedInactiveCounts,
          projects: state.projects.filter((row) => row.id !== action.payload.id),
          totalCount: state.totalCount - 1,
        };
  
      case STORE_MEMBERS:
        return {
          ...state,
          members: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default ClientReducer;
  