import Dashboard from "./pages/Dashboard";
import MyAccount from "./pages/MyAccount";
import Screenshots from "./pages/Screenshots";
import TimesheetViewEditPage from "./pages/TimesheetViewEdit";
import Reports from "./pages/Reports";
import Projects from "./pages/Projects";
import ProjectToDos from "./pages/ProjectToDos";
import Employees from "./pages/Employees";
import EditEmployee from "./pages/Employees/editEmployee";
import TimeAndActivity from "./pages/Reports/TimeAndActivity";
import EditTimePage from "./pages/TimesheetViewEdit/EditTimePage";
import DownloadApp from "./pages/DownloadApp";
import IncompleteTimesheets from "./pages/IncompleteTimesheets";
import BillableNonBillable from "./pages/BillableNonBillable";
import ReleaseNotes from "./pages/ReleaseNotes";
import Settings from "./pages/Settings";
import GDriveSettings from "./pages/GDrive";
import TimeActivitySetting from "./pages/Settings/TimeActivitySettings";
import ProjectTaskSettings from "./pages/Settings/ProjectTaskSettings";
import ProjectYields from "./pages/Reports/ProjectYields";
import WindowActivity from "./pages/Reports/WindowActivity";
import BillableNonBillableProjects from "./pages/BillableNonBillableProjects";
import DisplayAnnouncements from "./pages/announcements";
import BillableNonBillableClients from "./pages/BillableNonBillableClients";
import AllocatedTracked from "./pages/AllocatedTracked";
// import AppSettings from "./pages/Settings/AppSettings";
// import SplitTimePage from "./pages/TimesheetViewEdit/SplitTimePage";

const routes = [
  { path: "/", element: <Dashboard />, module: "Dashboard" },
  { path: "/my-account", element: <MyAccount />, module: "" },
  {
    path: "/timesheets",
    element: <TimesheetViewEditPage />,
    module: "Timesheets",
  },
  {
    path: "/timesheets/:timesheetId/edit",
    element: <EditTimePage />,
    module: "Timesheets",
  },
  {
    path: "/timesheets/:timesheetId/split",
    element: <EditTimePage />,
    module: "Timesheets",
  },
  { path: "/projects", element: <Projects />, module: "Projects" },
  { path: "/to-dos", element: <ProjectToDos />, module: "Todos" },
  { path: "/reports", element: <Reports />, module: "Reports" },
  { path: "/time-activity", element: <TimeAndActivity />, module: "Reports" },
  { path: "/window-activity", element: <WindowActivity />, module: "Reports" },
  { path: "/screenshots", element: <Screenshots />, module: "Reports" },
  {
    path: "/incomplete-timesheets",
    element: <IncompleteTimesheets />,
    module: "Reports",
  },
  {
    path: "/billable-nonbillable",
    element: <BillableNonBillable />,
    module: "Reports",
  },
  {
    path: "/tracked-allocated",
    element: <AllocatedTracked />,
    module: "Reports",
  },
  {
    path: "/billable-nonbillable-projects",
    element: <BillableNonBillableProjects />,
    module: "Reports",
  },
  {
    path: "/billable-nonbillable-clients",
    element: <BillableNonBillableClients />,
    module: "Reports",
  },
  // { path:'/project-yields', element:<ProjectYields />, module: "Reports" },
  { path: "/users", element: <Employees />, module: "Users" },
  { path: "/users/:editId/edit", element: <EditEmployee />, module: "Users" },
  { path: "/download-app", element: <DownloadApp />, module: "" },
  { path: "/release-notes", element: <ReleaseNotes />, module: "" },
  { path: "/settings", element: <Settings />, module: "Settings" },
  {
    path: "/settings/screenshots",
    element: <GDriveSettings />,
    module: "Settings",
  },
  {
    path: "/settings/time-activity",
    element: <TimeActivitySetting />,
    module: "Settings",
  },
  {
    path: "/settings/project-tasks",
    element: <ProjectTaskSettings />,
    module: "Settings",
  },
  {
    path: "/announcements",
    element: <DisplayAnnouncements />,
    module: "Announcements",
  },
  // { path:'/app-settings', element:<AppSettings />, module: "AppSettings" },
];

export default routes;
