import {
    // FETCH_ANNOUNCEMENT_START,
    // FETCH_ANNOUNCEMENT_SUCCESS,
    // FETCH_ANNOUNCEMENT_FAILURE,
    SET_TOTAL_ANNOUNCEMENTS,
    SET_SHOW_ALL_CHECKED_TRUE,
    SET_SHOW_ALL_CHECKED_FALSE,
    SET_MARKED_AS_READ_ID,
    UNSET_MARKED_AS_READ_ID,
  } from '../actionTypes';
  
  const initialState = {
    announcements: [],
    status: "idle",
    error: null,
    totalAnnouncements: 0,
    showAllChecked: false,
    markedAsReadId: null,
  };
  
  const announcementsReducer = (state = initialState, action) => {
    switch (action.type) {
    //   case FETCH_ANNOUNCEMENT_START:
    //     return { ...state, status: "loading" };
    //   case FETCH_ANNOUNCEMENT_SUCCESS:
    //     return { ...state, status: "succeeded", announcements: action.payload };
    //   case FETCH_ANNOUNCEMENT_FAILURE:
    //     return { ...state, status: "failed", error: action.payload.message };
      case SET_TOTAL_ANNOUNCEMENTS:
        return { ...state, totalAnnouncements: action.payload };
      case SET_SHOW_ALL_CHECKED_TRUE:
        return { ...state, showAllChecked: true };
      case SET_SHOW_ALL_CHECKED_FALSE:
        return { ...state, showAllChecked: false };
      case SET_MARKED_AS_READ_ID:
        return { ...state, markedAsReadId: action.payload };
      case UNSET_MARKED_AS_READ_ID:
        return { ...state, markedAsReadId: null };
      default:
        return state;
    }
  };
  
  export default announcementsReducer;