import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FiActivity } from "react-icons/fi";
import { BiCircleThreeQuarter } from "react-icons/bi";
import { FcSalesPerformance } from "react-icons/fc";
import { FitScreenOutlined } from "@mui/icons-material";
import BillableProject from "../../assets/layout/BillableProject.svg";
import bl_nbl_clients from "../../assets/layout/bl_nbl_clients.svg";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ROLES } from "../../lib/constants";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";

const ReportCard = ({ title, description, icon, link }) => {
  const navigate = useNavigate();

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} xxl={4}>
      <Box className="activity-card" onClick={() => navigate(link)}>
        <Box className="activity-content">
          <Typography variant="h6" component="h6" className="text-white">
            {title}
          </Typography>
          <Typography className="text-white text-desc">
            {description}
          </Typography>
        </Box>
        <Box className="activity-icons">
          {link === "/billable-nonbillable-projects" ? (
            <img
              src={BillableProject}
              alt="billable-project"
              className="bnb-project-svg"
            />
          ) : link === "/billable-nonbillable-clients" ? (
            <img
              src={bl_nbl_clients}
              alt="billable-clients"
              className="bnb-project-svg"
            />
          ) : (
            icon
          )}
        </Box>
      </Box>
    </Grid>
  );
};

const Reports = () => {
  const userDetails = useSelector((state) => state.user.data);

  return (
    <Box className="activity-card-wrapper mb-24">
      <Grid container spacing={3}>
        <ReportCard
          title="Time & Activity"
          description="See team member's time worked, activity levels per project or to-do"
          icon={<FiActivity />}
          link="/time-activity"
        />
        <ReportCard
          title="Screenshots"
          description="See team member's screenshots and activity level per project"
          icon={<FitScreenOutlined />}
          link="/screenshots"
        />
        {(userDetails?.user_role?.id === ROLES.admin ||
          userDetails?.user_role?.id === ROLES.manager) && (
          <ReportCard
            title="Incomplete Timesheets"
            description="See team member's who haven't completed their timesheet"
            icon={<BiCircleThreeQuarter stroke={0.2} />}
            link="/incomplete-timesheets"
          />
        )}
        {(userDetails?.user_role?.id === ROLES.admin ||
          userDetails?.user_role?.id === ROLES.manager ||
          userDetails.is_assistant_manager) && (
          <ReportCard
            title="Billable Vs. Non-Billable Members"
            description="See team member's billable vs. non-billable tracked hours"
            icon={<MonetizationOnOutlinedIcon />}
            link="/billable-nonbillable"
          />
        )}
        {console.log('userDetails',userDetails)}
       
        {(userDetails?.user_role?.id === ROLES.admin ||
          userDetails?.user_role?.id === ROLES.manager ||
          userDetails.is_assistant_manager) && (
          <ReportCard
            title="Billable vs Non-Billable Projects"
            description="See Project's billable vs. non-billable tracked hours"
            icon={<MonetizationOnOutlinedIcon />}
            link="/billable-nonbillable-projects"
          />
        )}
        {(userDetails?.user_role?.id === ROLES.admin ||
          userDetails?.user_role?.id === ROLES.manager ||
          userDetails.is_assistant_manager) && (
          <ReportCard
            title="Billable vs Non-Billable Clients"
            description="See Client Project's billable vs. non-billable tracked hours"
            icon={<PeopleOutlineIcon />}
            link="/billable-nonbillable-clients"
          />
        )}
        {(userDetails?.user_role?.id === ROLES.admin ||
          userDetails?.user_role?.id === ROLES.manager) && (
          <ReportCard
            title="Window Activity"
            description="See team member's window activity"
            icon={<DisplaySettingsIcon />}
            link="/window-activity"
          />
        )}
         {/* {(userDetails?.id == 15 ||
          userDetails?.id == 31) && (
          <ReportCard
            title="Tracked Vs. Allocated Time Of  Members"
            description="See team member's tracked vs. allocated time"
            icon={<MonetizationOnOutlinedIcon />}
            link="/tracked-allocated"
          />
        )} */}
      </Grid>
    </Box>
  );
};

export default Reports;
