import { Box, Fade, Modal, Typography, Slide } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import { RiCloseLine } from "react-icons/ri";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
// import { capitalizeFirstLetter } from '../../utils/helper';
// import AppreciationIcon from '../../assets/images/icons/appreciationmodalicon.svg';
// import moment from 'moment';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// import getLoggedInUserId from "../../utils/userId";
import axios from "axios";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { callApi } from "../../pages/announcements/announcementApi";
import { setTotalAnnouncements } from "../../store/redux/actions/announcement.action";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import { useDispatch } from "react-redux";
const AnnouncementModelSlider = ({
  openReadAllModal,
  viewAnnouncementOpen,
  setViewAnnouncementOpen,
  announcement,
  announcementList,
  unreadIds,
}) => {
  const userId = Cookies.get(process.env.REACT_APP_USER_ID_COOKIE);
  const dispatch = useDispatch();
  const handleSlideChange = async (oldIndex, newIndex) => {
    let unreadIds = [
      announcementList[oldIndex].id,
      announcementList[newIndex].id,
    ];

    await axios.patch(
      `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/${userId}/read`,
      {
        announcement_id: unreadIds,
        is_all_read: false,
      }
    );
    const response = await callApi({
      method: "get",
      url: `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/module/1`,
    });

    dispatch(setTotalAnnouncements(response?.data?.unreadCount));
  };
  useEffect(() => {
    async function readFirst() {
      // Mark the first announcement as read
      await axios.patch(
        `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/${userId}/read`,
        {
          announcement_id: [announcementList[0]?.id],
          is_all_read: false,
        }
      );
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/module/1`,
      });

      dispatch(setTotalAnnouncements(response?.data?.unreadCount));
    }
    if (viewAnnouncementOpen && announcementList?.length > 0) {
      readFirst();
    }
  }, [viewAnnouncementOpen, announcementList]);
  const settings = {
    dots: announcementList?.length > 1,
    arrow: announcementList?.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: announcementList?.length > 1,
    autoplay: false,
    prevArrow:
      announcementList?.length > 1 ? (
        <ChevronLeftOutlinedIcon sx={{ color: "black", fontSize: "35px" }} />
      ) : null,
    nextArrow:
      announcementList?.length > 1 ? (
        <ChevronRightOutlinedIcon sx={{ color: "black", fontSize: "35px" }} />
      ) : null,
    beforeChange: handleSlideChange,
  };

  // console.log(unreadIds,"unreadIds")
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={viewAnnouncementOpen}
        // onClose={setViewAnnouncementOpen(false)}
        closeAfterTransition
        disableAutoFocus
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Slide in={viewAnnouncementOpen} direction="down">
          <Box
            className="modal-wrapper-announcement-slider  modal-ui"
            sx={{
              position: "absolute",
              top: {
                xs: "10%",
                sm: "15%",
                md: "20%",
                lg: "20%",
              },
              left: {
                xs: "7%",
                sm: "7%",
                md: "15%",
                lg: "20%",
              },
              transition: "top 0.5s ease",
            }}
          >
            <Box className="modal-body">
              <Box
                variant="div"
                component="div"
                className="ann-title"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    gap: "12px",
                    alignItems: "center",
                  }}
                >
                  <CampaignOutlinedIcon
                    sx={{ fontSize: "35px" }}
                  ></CampaignOutlinedIcon>
                  <Typography variant="h6">New Announcement !</Typography>
                </Box>
                <Box variant="div" component="div" className="ann-btn-group">
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn announcement-btn custom-icon-btn"
                    disableRipple
                    endIcon={<RiCloseLine />}
                    onClick={async () => {
                      setViewAnnouncementOpen(false);
                      await axios.post(
                        `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/popupClosed`,
                        {
                          ids: unreadIds,
                        }
                      );
                    }}
                  ></Button>
                </Box>
              </Box>
              {/* {console.log(announcementList,"announcementList")} */}

              <Slider {...settings}>
                {announcementList?.length > 0
                  ? announcementList?.map((announcement, index) => {
                      return (
                        <Box
                          key={index}
                          variant="div"
                          component="div"
                          sx={{ minHeight: "100%", height: "100%" }}
                          className="announcement-detail-slider"
                        >
                          <Typography
                            variant="body1"
                            component="label"
                            className="announcement-title"
                            sx={{
                              paddingBottom: "8px",
                              cursor: "default",
                              whiteSpace: "pre-wrap",
                              color: "#7cb900 !important",
                            }}
                          >
                            {announcement?.title || "No Title"}
                          </Typography>
                          <Box
                            sx={{
                              width: "100%",
                              margin: "0px",
                              marginBottom: "25px",
                            }}
                          >
                            <Typography
                              variant="body1"
                              component="label"
                              className="announcement-desc"
                              sx={{
                                cursor: "default",
                                whiteSpace: "pre-wrap",
                                color: "black",
                                minHeight: {
                                  xs: "50vh", // Extra small screens
                                  sm: "40vh", // Small screens
                                  md: "30vh", // Medium screens
                                },
                                height: {
                                  xs: "50vh", // Extra small screens
                                  sm: "40vh", // Small screens
                                  md: "30vh", // Medium screens
                                },
                                maxHeight: "calc(100vh-200px)",
                                overflowY: "auto",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: `<div style="display:  flex; flex-direction: column;">${
                                  announcement?.description || "No Content"
                                }</div>`,
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "10px",
                            }}
                          >
                            <Typography
                              variant="body1"
                              component="div"
                              sx={{ fontSize: "15px" }}
                            >
                              {`${announcement?.authorInfo?.first_name} ${announcement?.authorInfo?.last_name}` ||
                                "Anonymous"}
                            </Typography>
                            <Typography
                              variant="body1"
                              component="div"
                              sx={{ fontSize: "15px" }}
                            >
                              {announcement?.formattedDate}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })
                  : null}
              </Slider>
            </Box>
          </Box>
        </Slide>
      </Modal>
    </>
  );
};

export default AnnouncementModelSlider;
