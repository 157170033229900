import { put, takeEvery, call } from 'redux-saga/effects';
import axios from 'axios';
import { FETCH_USER_LIST_STORE } from '../redux/actionTypes';
import {
//   FETCH_USER_LIST_STORE,
  getUserListStart,
  getUserListSuccess,
  getUserListFailure,
} from '../redux/actions/author.action';
// import { setFeedback } from './feedbackSlice';

axios.defaults.withCredentials = true;

function* fetchUserList(action) {
  try {
    yield put(getUserListStart());

    const response = yield call(axios.get, `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/authors`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${action.payload}`,
      },
    });

    yield put(getUserListSuccess(response.data));
    
  } catch (error) {
    yield put(getUserListFailure(error.message));
    
    yield put(
    //   setFeedback({
    //     status: "fail",
    //     message: error.response?.data?.message || "An error occurred",
    //     code: error.response?.status || 'unknown',
    //   })
    );
  }
}

function* watchFetchUserList() {
  yield takeEvery(FETCH_USER_LIST_STORE, fetchUserList);
}

export default watchFetchUserList;