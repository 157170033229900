import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  Box,
  Button,
  Drawer,
  Stack,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import {
  RiFilter2Line,
  RiCloseLine,
  RiSearchLine,
  RiCloseCircleLine,
} from "react-icons/ri";
import "../../styles/announcement.scss";
import Announcements from "./announcementsCard";
import { useLayoutEffect } from "react";
import { RiArrowLeftLine } from "react-icons/ri";
import { DateRangePicker } from "rsuite";
import { callApi } from "./announcementApi";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import {
  fetchUserListStore,
  getUserListStart,
} from "../../store/redux/actions/author.action";

const DisplayAnnouncements = () => {
  const [open, setOpen] = useState(false);
  const [announcementList, setAnnouncementList] = useState([]);
  const getTokenFromCookies = Cookies.get(process.env.REACT_APP_COOKIE_NAME);
  // Handle drawer toggle
  const handleDrawer = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const onCloseDrawer = () => {
    setOpen(false);
  };
  useLayoutEffect(() => {
    async function fetchAllAnnouncements() {
      try {
        const response = await callApi({
          method: "get",
          url: `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/module/1`,
        });

        setAnnouncementList(response);
      } catch (error) {
        console.error("Failed to fetch announcements:", error);
      }
    }

    fetchAllAnnouncements();
  }, []);

  const token = getTokenFromCookies;
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    try {
      dispatch(fetchUserListStore(token));
    } catch (error) {
      console.error("Loading Data Failed", error);
    }
  }, []);
  const authorData = useSelector((state) => {
    return state.userList.userList?.data;
  });
  const formattedAuthorData = authorData?.map((item) => ({
    value: item.id,
    label: item.full_name,
  }));

  const navigate = useNavigate();
  const [filterYear, setFilterYear] = useState("");
  const [filterMonth, setFilterMonth] = useState("");
  const [filterPostedBy, setFilterPostedBy] = useState("");
  const [filterType, setFilterType] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const [tempYear, setTempYear] = useState("");
  const [tempMonth, setTempMonth] = useState("");
  const [tempPostedBy, setTempPostedBy] = useState("");
  const [tempType, setTempType] = useState("");
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState(0);
  const [error, setError] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [tempDateFilter, setTempDateFilter] = useState("");
  const [viewAnnouncementOpen, setViewAnnouncementOpen] = useState(true);
  const handleApplyFilter = async () => {
    // setSearchLeave('');
    if (
      !tempYear &&
      !tempMonth &&
      !tempPostedBy &&
      !tempType &&
      !tempDateFilter
    ) {
      setError("Please select any one field");
      return;
    }
    setFilterApplied(true);
    setFilterYear(tempYear.value);
    setFilterMonth(tempMonth.value);
    setFilterPostedBy(tempPostedBy.value);
    setFilterType(tempType.value);
    setDateFilter(tempDateFilter);
    setFilterApplied(true);
    setOpen(false);
  };
  useLayoutEffect(() => {}, []);
  useEffect(() => {
    setSearchText("");
  }, [tempYear, tempMonth, tempPostedBy, tempType]);

  return (
    <>
      <Box className="announcement-content-header announcement-module-wrapper">
        <Typography variant="h2" component="h2" className="title-with-btn">
          {" "}
          <Button
            variant="text"
            className="action-btn ann-back-btn"
            sx={{ color: "black", backgroundColor: "transparent !important" }}
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => navigate("/")}
          ></Button>{" "}
          Announcements
          {filterApplied && (
            <>
              <Button
                variant="contained"
                size="large"
                className="outline-btn announcement-btn add-btn-filter"
                style={{ marginLeft: "8px" }}
                endIcon={<RiCloseLine />}
                disableRipple
                onClick={() => {
                  setFilterApplied(false);
                  setFilterMonth("");
                  setFilterYear("");
                  setFilterPostedBy("");
                  setFilterType("");
                  setTempMonth("");
                  setTempYear("");
                  setTempPostedBy("");
                  setTempType("");
                  setTempDateFilter("");
                  setDateFilter("");
                }}
              >
                Remove filter
              </Button>
            </>
          )}
        </Typography>
        <Box variant="div" component="div" className="ann-content-header-right">
          <Box variant="div" component="div" className="search-bar">
            <RiSearchLine />
            <TextField
              fullWidth
              id="WFH search"
              variant="outlined"
              size="small"
              placeholder="Search here"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            {searchText && (
              <RiCloseCircleLine
                className="cross-line"
                onClick={() => setSearchText("")}
              />
            )}
          </Box>
          <Button
            variant="contained"
            size="large"
            className="primary-btn announcement-btn add-btn"
            endIcon={<RiFilter2Line />}
            disableRipple
            onClick={handleDrawer}
          >
            Filters
          </Button>
        </Box>
      </Box>
      <Box>
        <Box className="announcement-card-wrapper">
          <Announcements
            status={status}
            setStatus={setStatus}
            viewAnnouncementOpen={viewAnnouncementOpen}
            setViewAnnouncementOpen={setViewAnnouncementOpen}
            filterYear={filterYear}
            filterMonth={filterMonth}
            filterPostedBy={filterPostedBy}
            filterType={filterType}
            searchText={searchText}
            dateFilter={dateFilter}
          />
        </Box>
      </Box>
      <Drawer
        className="side-drawer-2 report-side-drawer announcement-module-wrapper"
        anchor="right"
        open={open}
        onClose={onCloseDrawer}
      >
        <Box className="sidebar-tab-wrapper-2">
          <Box className="close-btn">
            <RiCloseLine onClick={onCloseDrawer} />
          </Box>
          <Box className="sidebar-tab">
            <Tab
              className="tab-label-btn contact-label"
              label="Filters"
              disabled
            />
          </Box>
        </Box>
        <Stack spacing={2} sx={{ padding: "24px !important" }}>
          <Box>
            <Typography variant="body1" component="label" className="ann-label">
              Select Date Range
            </Typography>
            <DateRangePicker
              placeholder="Select Year"
              name="year"
              // style={{ borderColor: "hsl(0, 0%, 80%) !important" }}
              className="joining-date-picker select-date-range"
              block
              fullWidth
              menuClassName="drawer-date-picker-container"
              format="dd-MM-yyyy"
              value={tempDateFilter}
              theme={(theme) => ({
                ...theme,
                borderRadius: 10,
                colors: {
                  ...theme.colors,
                  primary25: "var(--secondary-text-color)",
                  primary: "#8CC714",
                },
              })}
              onChange={(date) => {
                setTempDateFilter(date);
                setError("");
              }}
            />
            {error && (
              <Typography variant="body1" component="p" className="ann-error">
                {error}
              </Typography>
            )}
          </Box>
          <Box>
            <Typography variant="body1" component="label" className="ann-label">
              Posted By
            </Typography>
            <Select
              placeholder="Select Posted By"
              name="posted_by"
              className="multi-select-field"
              options={formattedAuthorData}
              classNamePrefix="select"
              value={
                tempPostedBy
                  ? { value: tempPostedBy.value, label: tempPostedBy.label }
                  : null
              }
              theme={(theme) => ({
                ...theme,
                borderRadius: 10,
                colors: {
                  ...theme.colors,
                  primary25: "var(--secondary-text-color)",
                  primary: "#8CC714",
                },
              })}
              onChange={(e) => {
                setTempPostedBy({ value: e.value, label: e.label });
                setError("");
              }}
            />
            {error && (
              <Typography variant="body1" component="p" className="ann-error">
                {error}
              </Typography>
            )}
          </Box>
          <Box>
            <Typography variant="body1" component="label" className="ann-label">
              Type
            </Typography>
            <Select
              placeholder="Select Type"
              name="type"
              className="multi-select-field"
              options={[
                { value: "important", label: "Important" },
                { value: "all", label: "All" },
              ]}
              value={
                tempType
                  ? { label: tempType.label, value: tempType.value }
                  : null
              }
              classNamePrefix="select"
              theme={(theme) => ({
                ...theme,
                borderRadius: 10,
                colors: {
                  ...theme.colors,
                  primary25: "var(--secondary-text-color)",
                  primary: "#8CC714",
                },
              })}
              onChange={(e) => {
                setTempType({ value: e.value, label: e.label });
                setError("");
              }}
            />
            {error && (
              <Typography variant="body1" component="p" className="ann-error">
                {error}
              </Typography>
            )}
          </Box>

          <Box className="filter-button-wrapper text-end">
            <Button
              variant="contained"
              size="large"
              className="primary-btn btn add-btn mx-2"
              onClick={handleApplyFilter}
              disableRipple
            >
              Apply
            </Button>
            <Button
              variant="contained"
              size="large"
              className="secondary-btn btn ml-16"
              disableRipple
              onClick={() => {
                setOpen(false);
                setError("");
              }}
            >
              Cancel
            </Button>
          </Box>
        </Stack>
      </Drawer>
    </>
  );
};

export default DisplayAnnouncements;
