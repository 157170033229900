import {
    deleteAPICall,
    getAPICall,
    patchAPICall,
    postAPICall,
    setIdInUrl,
  } from "../lib/callApi";
import { FETCH_CLIENT_LIST_API } from "../lib/constants";
  export const fetchClientsByUserId = async (params) => {
    const data = await getAPICall(FETCH_CLIENT_LIST_API);
    return data;
  };