import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SplitIcon from "../../assets/timesheet/split.png";
import { useDispatch, useSelector } from "react-redux";
import {
  setFeedback,
  setLoading,
} from "../../store/redux/actions/feedback.action";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  convertSecondsIntoHHMM,
  convertSecondsIntoTime,
  convertTimeInto12HoursFormat,
  formatPrintMemo,
} from "../../utils/helper";
import {
  deleteTimeSheet,
  fetchTimeSheetByDate,
  fetchWindowActivity,
  isUserUnderAssociateManager,
  moveTimeSheet,
} from "../../apis/timeSheet";
import {
  FiCheckCircle,
  FiEdit3,
  FiTrash2,
  FiSlash,
  FiEye,
} from "react-icons/fi";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import { useNavigate } from "react-router-dom";
import DeleteTime from "./DeleteTime";
import { showSuccessToast } from "../../utils/toast";
import { ROLES } from "../../lib/constants";
import ViewTimesheet from "./ViewTimesheet";
import ViewApplicationActivity from "./ViewApplicationActivity";

const DDMMYYYY = (inputDate) => {
  if (inputDate) {
    const dateParts = inputDate.split("-");
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  } else {
    return "-";
  }
};

const isSameDay = (inputDate) => {
  const today = new Date();
  const todayDate = `${today.getFullYear()}-${(today.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;
  return todayDate === inputDate;
};

const getCurrentTime = () => {
  const date = new Date();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${hours > 12 ? hours - 12 : hours}:${
    minutes < 10 ? "0" : ""
  }${minutes} ${hours >= 12 ? "pm" : "am"}`;
};

const TimeSheetTableRow = (props) => {
  const {
    row,
    valueTab,
    selectedProject,
    projectOptions,
    fetchTimeSheetData,
    open,
    setOpen,
    index,
    permissionsAccess,
    flag,
    setflag,
    all,
    row_wise,
  } = props;
  const [innerTimeSheetData, setInnerTimeSheetData] = useState([]);
  const userDetails = useSelector((state) => state.user.data);
  const [openmemo, setOpenmemo] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // delete time modal
  const [timeSheetId, setTimeSheetId] = useState("");
  const [deleteTimeModalOpen, setDeleteOpen] = useState(false);
  const [isUserUnderAManager, setisUserUnderAManager] = useState("false");
  const deleteModalOpen = (id) => {
    setTimeSheetId(id);
    setDeleteOpen(true);
  };
  const deleteModalClose = () => setDeleteOpen(false);

  // view work updates modal
  const [viewTimesheetsModalOpen, setViewTimesheetModalOpen] = useState(false);
  const [viewAppActivityModalOpen, setViewAppActivityModalOpen] =
    useState(false);
  const [windowDetails, setWindowDetails] = useState();
  const openViewTimesheetModal = () => setViewTimesheetModalOpen(true);
  const closeTimesheetModal = () => setViewTimesheetModalOpen(false);
  const openViewAppActivityModal = () => setViewAppActivityModalOpen(true);
  const closeAppActivityModal = () => setViewAppActivityModalOpen(false);
  // const [flag, setflag] = useState(0);
  const [expandedRows, setExpandedRows] = useState({}); // Object to track expanded state of each row

  useEffect(() => {
    const defaultExpanded = innerTimeSheetData.reduce((acc, item) => {
      acc[item.id] = false; // Set all rows to open
      return acc;
    }, {});
    setExpandedRows(defaultExpanded);
  }, [innerTimeSheetData]); // Runs when innerTimeSheetData changes

  const toggleRow = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id], // Toggle the expanded state for the specific row
    }));
  };
  const getFetchInnerTimeSheetParams = () => {
    let params = {
      full_timesheet: 1,
      approved: valueTab === 0 ? "no" : "yes",
      startDate: row.date,
      endDate: row.date,
      user_id: row.user_id,
    };
    if (selectedProject.value !== "") {
      params.project_id = [selectedProject.value];
    } else {
      let projectIds = [];
      projectOptions.forEach((row) => {
        if (row.value !== "") {
          projectIds.push(row.value);
        }
      });
      params.project_id = projectIds;
    }
    return params;
  };

  const fetchWindowActivityData = async () => {
    try {
      dispatch(setLoading(true));
      const params = {
        start_date: row?.date,
        end_date: row?.date,
        user_id: row?.user_id,
      };
      const response = await fetchWindowActivity(params);
      if (response?.data?.status === 200) {
        setWindowDetails(response?.data?.data);
        openViewAppActivityModal();
      } else {
        const errPayload = {
          code: response.data.status,
          status: "fail",
          message: response.data.message || "Something went wrong.",
        };
        dispatch(setFeedback(errPayload));
      }
    } catch (error) {
      dispatch(setLoading(false));
      const errPayload = {
        code: error.status,
        status: "fail",
        message:
          error.response.status === 404
            ? "No data found"
            : error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const fetchInnerTimeSheetData = async () => {
    try {
      dispatch(setLoading(true));
      const params = getFetchInnerTimeSheetParams();

      // API integration start
      const response = await fetchTimeSheetByDate(params);
      if (response.data.status === 200) {
        setOpen(index, true);
        setInnerTimeSheetData(response.data.data);
      } else {
        setInnerTimeSheetData([]);
        const errPayload = {
          code: response.data.status,
          status: "fail",
          message: response.data.message || "Something went wrong.",
        };
        dispatch(setFeedback(errPayload));
      }
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const deleteTimeSheetHandler = async (reason) => {
    try {
      dispatch(setLoading(true));
      const response = await deleteTimeSheet(timeSheetId, { reason });
      if (response.data.status === 200) {
        showSuccessToast(response.data.data);
        deleteModalClose();
        // fetchInnerTimeSheetData(row.user_id, row.date);
        setInnerTimeSheetData((prev) => {
          return prev.filter((row) => row.id !== timeSheetId);
        });
        setTimeSheetId("");
        fetchTimeSheetData();
      } else {
        const errPayload = {
          code: response.data.status,
          status: "fail",
          message: response.data.message || "Something went wrong.",
        };
        dispatch(setFeedback(errPayload));
      }
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const approveTimeSheetHandler = async () => {
    try {
      dispatch(setLoading(true));
      const params = getFetchInnerTimeSheetParams();

      const response = await fetchTimeSheetByDate(params);
      if (response.data.status === 200) {
        const timeSheetData = response.data.data;
        const timesheet_id = timeSheetData?.map((row) => row.id);

        const payload = { date: row.date, approved: "yes", timesheet_id };
        const response2 = await moveTimeSheet(payload);
        if (response2.data.status === 200) {
          // fetchTimeSheetData();
          setflag((prev) => !prev);
          setTimeout(() => {
            showSuccessToast("Timesheet approved successfully.");
          }, [300]);
        } else {
          const errPayload = {
            code: response2.data.status,
            status: "fail",
            message: response2.data.message || "Something went wrong.",
          };
          dispatch(setFeedback(errPayload));
        }
      } else {
        const errPayload = {
          code: response.data.status,
          status: "fail",
          message: response.data.message || "Something went wrong.",
        };
        dispatch(setFeedback(errPayload));
      }
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const unApproveTimeSheetHandler = async () => {
    try {
      dispatch(setLoading(true));
      const params = getFetchInnerTimeSheetParams();

      // API integration start
      const response = await fetchTimeSheetByDate(params);
      const timeSheetData = response.data.data;
      const timesheet_id = timeSheetData.map((row) => row.id);

      const payload = { date: row.date, approved: "no", timesheet_id };
      const response2 = await moveTimeSheet(payload);
      if (response2.data.status === 200) {
        // fetchTimeSheetData();
        setflag((prev) => !prev);
        setTimeout(() => {
          showSuccessToast("Timesheet un-approved successfully.");
        }, [300]);
      } else {
        const errPayload = {
          code: response2.data.status,
          status: "fail",
          message: response2.data.message || "Something went wrong.",
        };
        dispatch(setFeedback(errPayload));
      }
    } catch (error) {
      const errPayload = {
        code: error.status,
        status: "fail",
        message: error.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    } finally {
      dispatch(setLoading(false));
    }
  };
  // useEffect(() => {
  //   fetchTimeSheetData();
  // }, [flag]);
  const isUserUnderAssociateManagerHandler = async () => {
    const payload = row.user_id;

    const variable = await isUserUnderAssociateManager(payload);
    setisUserUnderAManager(variable.data.data.is_associate);
  };
  useEffect(() => {
    isUserUnderAssociateManagerHandler();
  }, []);
  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          {open ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setInnerTimeSheetData([]);
                setOpen(index, false);
              }}
            >
              <RemoveIcon />
            </IconButton>
          ) : (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={fetchInnerTimeSheetData}
            >
              <AddIcon />
            </IconButton>
          )}
        </TableCell>
        <TableCell align="left">{DDMMYYYY(row.date)}</TableCell>
        <TableCell align="left">
          {row["user.first_name"]} {row["user.last_name"]}
        </TableCell>
        <TableCell align="left">
          {convertSecondsIntoHHMM(row.total_manual_time)} Hrs /{" "}
          {convertSecondsIntoHHMM(row.tracked_time)} Hrs
        </TableCell>
        <TableCell align="left">
          {convertSecondsIntoHHMM(row["user_activities.total_billable_time"])}{" "}
          Hrs /{" "}
          {convertSecondsIntoHHMM(row["user_activities.total_unbillable_time"])}{" "}
          Hrs
        </TableCell>
        <TableCell align="left">
          {convertSecondsIntoHHMM(row.total_tracked_time)} Hrs /{" "}
          {convertSecondsIntoHHMM(
            row.total_tracked_time_for_all_project
              ? row.total_tracked_time_for_all_project
              : row.total_tracked_time
          )}{" "}
          Hrs
        </TableCell>
        <TableCell align="left">{row?.activity}%</TableCell>
        <TableCell align="left">
          <Box
            className="table-action-btn"
            style={
              userDetails?.user_role?.id === ROLES.employee &&
              !userDetails.is_assistant_manager
                ? { justifyContent: "center" }
                : {}
            }
          >
            {userDetails?.user_role?.id === ROLES.admin &&
              permissionsAccess.edit && (
                <>
                  {valueTab === 0 && !isSameDay(row.date) && (
                    <Tooltip title="Approve">
                      <Button
                        className="btn btn-primary"
                        onClick={approveTimeSheetHandler}
                      >
                        <FiCheckCircle />
                      </Button>
                    </Tooltip>
                  )}

                  {valueTab === 1 && !isSameDay(row.date) && (
                    <Tooltip title="Un-Approve">
                      <Button
                        className="btn btn-primary"
                        onClick={unApproveTimeSheetHandler}
                      >
                        <FiSlash />
                      </Button>
                    </Tooltip>
                  )}
                </>
              )}
            {((userDetails?.user_role?.id === ROLES.manager &&
              // !userDetails.is_assistant_manager &&
              (row["is_assistant_manager"] ||
                row["user.role"] !== ROLES.manager) &&
              isUserUnderAManager &&
              // false && // is timesheet user is under user category under Login user at any project
              userDetails.id !== row.user_id &&
              permissionsAccess.edit) ||
              (userDetails.is_assistant_manager &&
                isUserUnderAManager &&
                // false && // is timesheet user is under user category under Login user at any project
                row["user.role"] !== ROLES.manager &&
                userDetails.id !== row.user_id)) && (
              <>
                {valueTab === 0 && !isSameDay(row.date) && (
                  <Tooltip title="Approve">
                    <Button
                      className="btn btn-primary"
                      onClick={approveTimeSheetHandler}
                    >
                      <FiCheckCircle />
                    </Button>
                  </Tooltip>
                )}

                {valueTab === 1 && !isSameDay(row.date) && (
                  <Tooltip title="Un-Approve">
                    <Button
                      className="btn btn-primary"
                      onClick={unApproveTimeSheetHandler}
                    >
                      <FiSlash />
                    </Button>
                  </Tooltip>
                )}
              </>
            )}
            <Tooltip title="Work Updates">
              <Button
                className="btn btn-primary"
                onClick={openViewTimesheetModal}
              >
                <FiEye />
              </Button>
            </Tooltip>
            {(userDetails?.user_role?.id === ROLES.admin ||
              userDetails?.user_role?.id === ROLES.manager) && (
              <Tooltip title="Application wise details">
                <Button
                  className="btn btn-primary"
                  onClick={fetchWindowActivityData}
                >
                  <DisplaySettingsIcon />
                </Button>
              </Tooltip>
            )}
          </Box>
        </TableCell>
      </TableRow>
      {/* Row for Inner Table */}
      <TableRow>
        <TableCell
          style={{ padding: open ? "12px 24px 12px 24px" : "0px" }}
          className="inner-table-cell"
          colSpan={8}
        >
          <Collapse in={open} timeout="auto" unmountOnExit={true}>
            <Box sx={{ marginTop: 1, marginBottom: 1 }}>
              <Table size="small" className="inner-view-edit-table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Project & Task</TableCell>
                    <TableCell align="left">Manual Vs. Tracked</TableCell>
                    <TableCell align="left">Billed Vs. Unbilled</TableCell>
                    <TableCell align="left">Duration</TableCell>
                    <TableCell align="left">Time</TableCell>
                    <TableCell align="left">
                      {userDetails?.user_role?.id !== ROLES.viewer &&
                        (permissionsAccess.edit || permissionsAccess.delete) &&
                        valueTab !== 1 &&
                        "Actions"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {innerTimeSheetData?.map((item) => (
                    <React.Fragment key={item.id + "_inner_timeSheet"}>
                      <TableRow
                        className="first-row"
                        onClick={() => toggleRow(item.id)}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell
                          align="left"
                          style={{ position: "relative", maxWidth: "360px" }}
                        >
                          {/* <Button
                            onClick={() => toggleRow(item.id)}
                            style={{ border: "2px solid red" }}
                          > */}
                          {expandedRows[item.id] ? (
                            <KeyboardArrowUpIcon
                              sx={{ marginLeft: "-5px", fontSize: "20px" }}
                            />
                          ) : (
                            <KeyboardArrowDownIcon
                              sx={{ marginLeft: "-5px", fontSize: "20px" }}
                            />
                          )}{" "}
                          {/* Toggle arrow icon */}
                          {/* </Button> */}
                          <b>{item["project.title"]}</b> <br />
                          <Tooltip
                            title={
                              <div style={{ color: "white", fontSize: "13px" }}>
                                {item["todo.description"]}
                              </div>
                            }
                            arrow
                          >
                            <em style={{ marginLeft: "18px" }}>
                              {item["todo.description"].length < 50
                                ? item["todo.description"]
                                : item["todo.description"].slice(0, 48) + "..."}
                            </em>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="left">
                          {item.manual_entry === "yes"
                            ? `${convertSecondsIntoHHMM(
                                item.tracked_time
                              )} Hrs / 00:00 Hrs`
                            : `00:00 Hrs / ${convertSecondsIntoHHMM(
                                item.tracked_time
                              )} Hrs`}
                        </TableCell>
                        <TableCell align="left">
                          {convertSecondsIntoHHMM(
                            item["user_activities.total_billable_time"]
                          )}{" "}
                          Hrs /{" "}
                          {convertSecondsIntoHHMM(
                            item["user_activities.total_unbillable_time"]
                          )}{" "}
                          Hrs
                        </TableCell>
                        <TableCell align="left">
                          {convertSecondsIntoTime(item.tracked_time)}
                        </TableCell>
                        <TableCell align="left">
                          {`${convertTimeInto12HoursFormat(
                            item.start_date,
                            1
                          )}-${
                            item.end_date
                              ? convertTimeInto12HoursFormat(item.end_date, 1)
                              : getCurrentTime()
                          }`}
                        </TableCell>
                        <TableCell align="left">
                          {userDetails?.user_role?.id !== ROLES.viewer &&
                            (permissionsAccess.edit ||
                              permissionsAccess.delete) && (
                              <Box className="table-action-btn">
                                {item.end_date !== null && valueTab !== 1 && (
                                  <>
                                    {permissionsAccess.edit && (
                                      <>
                                        <Tooltip title="Edit Timesheet">
                                          <Button
                                            className="btn btn-primary"
                                            onClick={() =>
                                              navigate(
                                                `/timesheets/${item.id}/edit`,
                                                {
                                                  state: {
                                                    assignee: item.user_id,
                                                    isManual: item.manual_entry,
                                                  },
                                                }
                                              )
                                            }
                                          >
                                            <FiEdit3 />
                                          </Button>
                                        </Tooltip>
                                        <Tooltip title="Split Timesheet">
                                          <Button
                                            className="btn btn-primary"
                                            onClick={() =>
                                              navigate(
                                                `/timesheets/${item.id}/split`,
                                                {
                                                  state: {
                                                    assignee: item.user_id,
                                                    isManual: item.manual_entry,
                                                  },
                                                }
                                              )
                                            }
                                          >
                                            <img src={SplitIcon} alt="split" />
                                          </Button>
                                        </Tooltip>
                                      </>
                                    )}
                                    {permissionsAccess.delete && (
                                      <Tooltip title="Remove Timesheet">
                                        <Button
                                          className="btn btn-primary"
                                          onClick={deleteModalOpen.bind(
                                            null,
                                            item.id
                                          )}
                                        >
                                          <FiTrash2 />
                                        </Button>
                                      </Tooltip>
                                    )}
                                  </>
                                )}
                              </Box>
                            )}
                        </TableCell>
                      </TableRow>

                      {/* Conditionally render the second row */}
                      {expandedRows[item.id] && (
                        <TableRow className="second-row">
                          <TableCell
                            colSpan={6}
                            sx={{ paddingLeft: "39px !important" }}
                          >
                            <b>Memo: </b>
                            <br />
                            {formatPrintMemo(item.memo)}
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {viewTimesheetsModalOpen && (
        <ViewTimesheet
          openModal={viewTimesheetsModalOpen}
          closeModal={closeTimesheetModal}
          userDetails={row}
          valueTab={valueTab}
          projectId={selectedProject}
          getFetchInnerTimeSheetParams={getFetchInnerTimeSheetParams}
          date={row.date}
          fetchTimeSheetData={fetchTimeSheetData}
          permissionsAccess={permissionsAccess}
          timeSheetUserRole={row["user.role"]}
          timeSheetUserRoleId={row.user_id}
          isAssistantManager={row["is_assistant_manager"]}
          isUserUnderAManager={isUserUnderAManager}
        />
      )}
      {viewAppActivityModalOpen && (
        <ViewApplicationActivity
          openModal={viewAppActivityModalOpen}
          closeModal={closeAppActivityModal}
          user={row}
          windowDetails={windowDetails}
        />
      )}
      {deleteTimeModalOpen && (
        <DeleteTime
          deleteTimeModalOpen={deleteTimeModalOpen}
          deleteModalClose={deleteModalClose}
          deleteTimeSheetHandler={deleteTimeSheetHandler}
          timeSheetId={timeSheetId}
        />
      )}
    </>
  );
};

export default TimeSheetTableRow;
